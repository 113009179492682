(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cL.Z === region.a_.Z)
	{
		return 'on line ' + region.cL.Z;
	}
	return 'on lines ' + region.cL.Z + ' through ' + region.a_.Z;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cn,
		impl.cX,
		impl.cQ,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bf: func(record.bf),
		aL: record.aL,
		aI: record.aI
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bf;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.aL;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.aI) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cn,
		impl.cX,
		impl.cQ,
		function(sendToApp, initialModel) {
			var view = impl.cZ;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cn,
		impl.cX,
		impl.cQ,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.aK && impl.aK(sendToApp)
			var view = impl.cZ;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.b2);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cT) && (_VirtualDom_doc.title = title = doc.cT);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.cw;
	var onUrlRequest = impl.cx;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		aK: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bu === next.bu
							&& curr.a8 === next.a8
							&& curr.bn.a === next.bn.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		cn: function(flags)
		{
			return A3(impl.cn, flags, _Browser_getUrl(), key);
		},
		cZ: impl.cZ,
		cX: impl.cX,
		cQ: impl.cQ
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cj: 'hidden', b7: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cj: 'mozHidden', b7: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cj: 'msHidden', b7: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cj: 'webkitHidden', b7: 'webkitvisibilitychange' }
		: { cj: 'hidden', b7: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bC: _Browser_getScene(),
		bN: {
			bR: _Browser_window.pageXOffset,
			bS: _Browser_window.pageYOffset,
			bQ: _Browser_doc.documentElement.clientWidth,
			a6: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bQ: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		a6: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bC: {
				bQ: node.scrollWidth,
				a6: node.scrollHeight
			},
			bN: {
				bR: node.scrollLeft,
				bS: node.scrollTop,
				bQ: node.clientWidth,
				a6: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bC: _Browser_getScene(),
			bN: {
				bR: x,
				bS: y,
				bQ: _Browser_doc.documentElement.clientWidth,
				a6: _Browser_doc.documentElement.clientHeight
			},
			cd: {
				bR: x + rect.left,
				bS: y + rect.top,
				bQ: rect.width,
				a6: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.a1.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.a1.b, xhr)); });
		$elm$core$Maybe$isJust(request.cV) && _Http_track(router, xhr, request.cV.a);

		try {
			xhr.open(request.cs, request.bM, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.bM));
		}

		_Http_configureRequest(xhr, request);

		request.b2.a && xhr.setRequestHeader('Content-Type', request.b2.a);
		xhr.send(request.b2.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ci; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cS.a || 0;
	xhr.responseType = request.a1.d;
	xhr.withCredentials = request.bV;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		bM: xhr.responseURL,
		cM: xhr.status,
		cN: xhr.statusText,
		ci: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			cK: event.loaded,
			bE: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			cH: event.loaded,
			bE: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $author$project$Main$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.g) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.j);
		} else {
			var treeLen = builder.g * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.k) : builder.k;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.g);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.j);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{k: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, j: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {a5: fragment, a8: host, bi: path, bn: port_, bu: protocol, bv: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Main$NotFoundPage = {$: 0};
var $author$project$Main$EditQuotePage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$EditQuotePageMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$NewQuotePage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$NewQuotePageMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$PayQuotePage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$PayQuotePageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$PolicyBillingPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$PolicyBillingPageMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$ShowPolicyPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$ShowPolicyPageMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$ShowQuotePage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$ShowQuotePageMsg = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Page$EditQuote$Initialising = {$: 0};
var $author$project$Page$EditQuote$ReceiveDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$EditQuote$GotQuote = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quote$Quote = F9(
	function (id, number, currency, quoteItems, bill, customer, policyId, startsAt, billingCycle) {
		return {N: bill, b0: billingCycle, ca: currency, e: customer, ck: id, cv: number, cD: policyId, h: quoteItems, ao: startsAt};
	});
var $author$project$Bill$Bill = F6(
	function (currency, lineItems, taxes, total, totalInclTax, billingCycleOptions) {
		return {b1: billingCycleOptions, ca: currency, cr: lineItems, cR: taxes, bK: total, cU: totalInclTax};
	});
var $author$project$Bill$BillingCycleOption = F3(
	function (unit, paymentAmount, count) {
		return {b9: count, cA: paymentAmount, cW: unit};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Bill$AnnualBilling = 1;
var $author$project$Bill$MonthlyBilling = 0;
var $author$project$Bill$stringToBillingCycleUnit = function (s) {
	switch (s) {
		case 'monthly':
			return $elm$core$Maybe$Just(0);
		case 'annual':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Bill$decodeBillingCycleUnit = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $author$project$Bill$stringToBillingCycleUnit(s);
		if (!_v0.$) {
			var u = _v0.a;
			return $elm$json$Json$Decode$succeed(u);
		} else {
			return $elm$json$Json$Decode$fail('Unknown billing cycle unit ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Bill$decodeBillingCycleOption = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'count',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'paymentAmount',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'unit',
			$author$project$Bill$decodeBillingCycleUnit,
			$elm$json$Json$Decode$succeed($author$project$Bill$BillingCycleOption))));
var $Chadtech$elm_money$Money$AED = 4;
var $Chadtech$elm_money$Money$AFN = 5;
var $Chadtech$elm_money$Money$ALL = 6;
var $Chadtech$elm_money$Money$AMD = 7;
var $Chadtech$elm_money$Money$ANG = 129;
var $Chadtech$elm_money$Money$AOA = 120;
var $Chadtech$elm_money$Money$ARS = 8;
var $Chadtech$elm_money$Money$AUD = 9;
var $Chadtech$elm_money$Money$AWG = 122;
var $Chadtech$elm_money$Money$AZN = 10;
var $Chadtech$elm_money$Money$BAM = 11;
var $Chadtech$elm_money$Money$BBD = 124;
var $Chadtech$elm_money$Money$BDT = 12;
var $Chadtech$elm_money$Money$BGN = 13;
var $Chadtech$elm_money$Money$BHD = 14;
var $Chadtech$elm_money$Money$BIF = 15;
var $Chadtech$elm_money$Money$BMD = 125;
var $Chadtech$elm_money$Money$BND = 16;
var $Chadtech$elm_money$Money$BOB = 17;
var $Chadtech$elm_money$Money$BRL = 18;
var $Chadtech$elm_money$Money$BSD = 123;
var $Chadtech$elm_money$Money$BTC = 3;
var $Chadtech$elm_money$Money$BTN = 126;
var $Chadtech$elm_money$Money$BWP = 19;
var $Chadtech$elm_money$Money$BYN = 20;
var $Chadtech$elm_money$Money$BZD = 21;
var $Chadtech$elm_money$Money$CAD = 1;
var $Chadtech$elm_money$Money$CDF = 22;
var $Chadtech$elm_money$Money$CHF = 23;
var $Chadtech$elm_money$Money$CLP = 24;
var $Chadtech$elm_money$Money$CNY = 25;
var $Chadtech$elm_money$Money$COP = 26;
var $Chadtech$elm_money$Money$CRC = 27;
var $Chadtech$elm_money$Money$CUP = 128;
var $Chadtech$elm_money$Money$CVE = 28;
var $Chadtech$elm_money$Money$CZK = 29;
var $Chadtech$elm_money$Money$DJF = 30;
var $Chadtech$elm_money$Money$DKK = 31;
var $Chadtech$elm_money$Money$DOP = 32;
var $Chadtech$elm_money$Money$DZD = 33;
var $Chadtech$elm_money$Money$EEK = 34;
var $Chadtech$elm_money$Money$EGP = 35;
var $Chadtech$elm_money$Money$ERN = 36;
var $Chadtech$elm_money$Money$ETB = 37;
var $Chadtech$elm_money$Money$EUR = 2;
var $Chadtech$elm_money$Money$FJD = 132;
var $Chadtech$elm_money$Money$FKP = 131;
var $Chadtech$elm_money$Money$GBP = 38;
var $Chadtech$elm_money$Money$GEL = 39;
var $Chadtech$elm_money$Money$GHS = 40;
var $Chadtech$elm_money$Money$GIP = 135;
var $Chadtech$elm_money$Money$GMD = 134;
var $Chadtech$elm_money$Money$GNF = 41;
var $Chadtech$elm_money$Money$GTQ = 42;
var $Chadtech$elm_money$Money$GYD = 136;
var $Chadtech$elm_money$Money$HKD = 43;
var $Chadtech$elm_money$Money$HNL = 44;
var $Chadtech$elm_money$Money$HRK = 45;
var $Chadtech$elm_money$Money$HTG = 137;
var $Chadtech$elm_money$Money$HUF = 46;
var $Chadtech$elm_money$Money$IDR = 47;
var $Chadtech$elm_money$Money$ILS = 48;
var $Chadtech$elm_money$Money$INR = 49;
var $Chadtech$elm_money$Money$IQD = 50;
var $Chadtech$elm_money$Money$IRR = 51;
var $Chadtech$elm_money$Money$ISK = 52;
var $Chadtech$elm_money$Money$JMD = 53;
var $Chadtech$elm_money$Money$JOD = 54;
var $Chadtech$elm_money$Money$JPY = 55;
var $Chadtech$elm_money$Money$KES = 56;
var $Chadtech$elm_money$Money$KGS = 139;
var $Chadtech$elm_money$Money$KHR = 57;
var $Chadtech$elm_money$Money$KMF = 58;
var $Chadtech$elm_money$Money$KPW = 138;
var $Chadtech$elm_money$Money$KRW = 59;
var $Chadtech$elm_money$Money$KWD = 60;
var $Chadtech$elm_money$Money$KYD = 127;
var $Chadtech$elm_money$Money$KZT = 61;
var $Chadtech$elm_money$Money$LAK = 62;
var $Chadtech$elm_money$Money$LBP = 63;
var $Chadtech$elm_money$Money$LKR = 64;
var $Chadtech$elm_money$Money$LRD = 141;
var $Chadtech$elm_money$Money$LSL = 140;
var $Chadtech$elm_money$Money$LTL = 65;
var $Chadtech$elm_money$Money$LVL = 66;
var $Chadtech$elm_money$Money$LYD = 67;
var $Chadtech$elm_money$Money$MAD = 68;
var $Chadtech$elm_money$Money$MDL = 69;
var $Chadtech$elm_money$Money$MGA = 70;
var $Chadtech$elm_money$Money$MKD = 71;
var $Chadtech$elm_money$Money$MMK = 72;
var $Chadtech$elm_money$Money$MNT = 145;
var $Chadtech$elm_money$Money$MOP = 73;
var $Chadtech$elm_money$Money$MRU = 144;
var $Chadtech$elm_money$Money$MUR = 74;
var $Chadtech$elm_money$Money$MVR = 143;
var $Chadtech$elm_money$Money$MWK = 142;
var $Chadtech$elm_money$Money$MXN = 75;
var $Chadtech$elm_money$Money$MYR = 76;
var $Chadtech$elm_money$Money$MZN = 77;
var $Chadtech$elm_money$Money$NAD = 78;
var $Chadtech$elm_money$Money$NGN = 79;
var $Chadtech$elm_money$Money$NIO = 80;
var $Chadtech$elm_money$Money$NOK = 81;
var $Chadtech$elm_money$Money$NPR = 82;
var $Chadtech$elm_money$Money$NZD = 83;
var $Chadtech$elm_money$Money$OMR = 84;
var $Chadtech$elm_money$Money$PAB = 85;
var $Chadtech$elm_money$Money$PEN = 86;
var $Chadtech$elm_money$Money$PGK = 146;
var $Chadtech$elm_money$Money$PHP = 87;
var $Chadtech$elm_money$Money$PKR = 88;
var $Chadtech$elm_money$Money$PLN = 89;
var $Chadtech$elm_money$Money$PYG = 90;
var $Chadtech$elm_money$Money$QAR = 91;
var $Chadtech$elm_money$Money$RON = 92;
var $Chadtech$elm_money$Money$RSD = 93;
var $Chadtech$elm_money$Money$RUB = 94;
var $Chadtech$elm_money$Money$RWF = 95;
var $Chadtech$elm_money$Money$SAR = 96;
var $Chadtech$elm_money$Money$SBD = 152;
var $Chadtech$elm_money$Money$SCR = 150;
var $Chadtech$elm_money$Money$SDG = 97;
var $Chadtech$elm_money$Money$SEK = 98;
var $Chadtech$elm_money$Money$SGD = 99;
var $Chadtech$elm_money$Money$SHP = 147;
var $Chadtech$elm_money$Money$SLL = 151;
var $Chadtech$elm_money$Money$SOS = 100;
var $Chadtech$elm_money$Money$SRD = 154;
var $Chadtech$elm_money$Money$SSP = 153;
var $Chadtech$elm_money$Money$STN = 149;
var $Chadtech$elm_money$Money$SYP = 101;
var $Chadtech$elm_money$Money$SZL = 130;
var $Chadtech$elm_money$Money$THB = 102;
var $Chadtech$elm_money$Money$TJS = 155;
var $Chadtech$elm_money$Money$TMT = 156;
var $Chadtech$elm_money$Money$TND = 103;
var $Chadtech$elm_money$Money$TOP = 104;
var $Chadtech$elm_money$Money$TRY = 105;
var $Chadtech$elm_money$Money$TTD = 106;
var $Chadtech$elm_money$Money$TWD = 107;
var $Chadtech$elm_money$Money$TZS = 108;
var $Chadtech$elm_money$Money$UAH = 109;
var $Chadtech$elm_money$Money$UGX = 110;
var $Chadtech$elm_money$Money$USD = 0;
var $Chadtech$elm_money$Money$UYU = 111;
var $Chadtech$elm_money$Money$UZS = 112;
var $Chadtech$elm_money$Money$VEF = 113;
var $Chadtech$elm_money$Money$VES = 158;
var $Chadtech$elm_money$Money$VND = 114;
var $Chadtech$elm_money$Money$VUV = 157;
var $Chadtech$elm_money$Money$WST = 148;
var $Chadtech$elm_money$Money$XAF = 115;
var $Chadtech$elm_money$Money$XCD = 121;
var $Chadtech$elm_money$Money$XOF = 116;
var $Chadtech$elm_money$Money$XPF = 133;
var $Chadtech$elm_money$Money$YER = 117;
var $Chadtech$elm_money$Money$ZAR = 118;
var $Chadtech$elm_money$Money$ZMK = 119;
var $Chadtech$elm_money$Money$ZMW = 159;
var $Chadtech$elm_money$Money$ZWL = 160;
var $elm$core$String$toUpper = _String_toUpper;
var $Chadtech$elm_money$Money$fromString = function (str) {
	var _v0 = $elm$core$String$toUpper(str);
	switch (_v0) {
		case 'USD':
			return $elm$core$Maybe$Just(0);
		case 'CAD':
			return $elm$core$Maybe$Just(1);
		case 'EUR':
			return $elm$core$Maybe$Just(2);
		case 'BTC':
			return $elm$core$Maybe$Just(3);
		case 'AED':
			return $elm$core$Maybe$Just(4);
		case 'AFN':
			return $elm$core$Maybe$Just(5);
		case 'ALL':
			return $elm$core$Maybe$Just(6);
		case 'AMD':
			return $elm$core$Maybe$Just(7);
		case 'ARS':
			return $elm$core$Maybe$Just(8);
		case 'AUD':
			return $elm$core$Maybe$Just(9);
		case 'AZN':
			return $elm$core$Maybe$Just(10);
		case 'BAM':
			return $elm$core$Maybe$Just(11);
		case 'BDT':
			return $elm$core$Maybe$Just(12);
		case 'BGN':
			return $elm$core$Maybe$Just(13);
		case 'BHD':
			return $elm$core$Maybe$Just(14);
		case 'BIF':
			return $elm$core$Maybe$Just(15);
		case 'BND':
			return $elm$core$Maybe$Just(16);
		case 'BOB':
			return $elm$core$Maybe$Just(17);
		case 'BRL':
			return $elm$core$Maybe$Just(18);
		case 'BWP':
			return $elm$core$Maybe$Just(19);
		case 'BYN':
			return $elm$core$Maybe$Just(20);
		case 'BZD':
			return $elm$core$Maybe$Just(21);
		case 'CDF':
			return $elm$core$Maybe$Just(22);
		case 'CHF':
			return $elm$core$Maybe$Just(23);
		case 'CLP':
			return $elm$core$Maybe$Just(24);
		case 'CNY':
			return $elm$core$Maybe$Just(25);
		case 'COP':
			return $elm$core$Maybe$Just(26);
		case 'CRC':
			return $elm$core$Maybe$Just(27);
		case 'CVE':
			return $elm$core$Maybe$Just(28);
		case 'CZK':
			return $elm$core$Maybe$Just(29);
		case 'DJF':
			return $elm$core$Maybe$Just(30);
		case 'DKK':
			return $elm$core$Maybe$Just(31);
		case 'DOP':
			return $elm$core$Maybe$Just(32);
		case 'DZD':
			return $elm$core$Maybe$Just(33);
		case 'EEK':
			return $elm$core$Maybe$Just(34);
		case 'EGP':
			return $elm$core$Maybe$Just(35);
		case 'ERN':
			return $elm$core$Maybe$Just(36);
		case 'ETB':
			return $elm$core$Maybe$Just(37);
		case 'GBP':
			return $elm$core$Maybe$Just(38);
		case 'GEL':
			return $elm$core$Maybe$Just(39);
		case 'GHS':
			return $elm$core$Maybe$Just(40);
		case 'GNF':
			return $elm$core$Maybe$Just(41);
		case 'GTQ':
			return $elm$core$Maybe$Just(42);
		case 'HKD':
			return $elm$core$Maybe$Just(43);
		case 'HNL':
			return $elm$core$Maybe$Just(44);
		case 'HRK':
			return $elm$core$Maybe$Just(45);
		case 'HUF':
			return $elm$core$Maybe$Just(46);
		case 'IDR':
			return $elm$core$Maybe$Just(47);
		case 'ILS':
			return $elm$core$Maybe$Just(48);
		case 'INR':
			return $elm$core$Maybe$Just(49);
		case 'IQD':
			return $elm$core$Maybe$Just(50);
		case 'IRR':
			return $elm$core$Maybe$Just(51);
		case 'ISK':
			return $elm$core$Maybe$Just(52);
		case 'JMD':
			return $elm$core$Maybe$Just(53);
		case 'JOD':
			return $elm$core$Maybe$Just(54);
		case 'JPY':
			return $elm$core$Maybe$Just(55);
		case 'KES':
			return $elm$core$Maybe$Just(56);
		case 'KHR':
			return $elm$core$Maybe$Just(57);
		case 'KMF':
			return $elm$core$Maybe$Just(58);
		case 'KRW':
			return $elm$core$Maybe$Just(59);
		case 'KWD':
			return $elm$core$Maybe$Just(60);
		case 'KZT':
			return $elm$core$Maybe$Just(61);
		case 'LAK':
			return $elm$core$Maybe$Just(62);
		case 'LBP':
			return $elm$core$Maybe$Just(63);
		case 'LKR':
			return $elm$core$Maybe$Just(64);
		case 'LTL':
			return $elm$core$Maybe$Just(65);
		case 'LVL':
			return $elm$core$Maybe$Just(66);
		case 'LYD':
			return $elm$core$Maybe$Just(67);
		case 'MAD':
			return $elm$core$Maybe$Just(68);
		case 'MDL':
			return $elm$core$Maybe$Just(69);
		case 'MGA':
			return $elm$core$Maybe$Just(70);
		case 'MKD':
			return $elm$core$Maybe$Just(71);
		case 'MMK':
			return $elm$core$Maybe$Just(72);
		case 'MOP':
			return $elm$core$Maybe$Just(73);
		case 'MUR':
			return $elm$core$Maybe$Just(74);
		case 'MXN':
			return $elm$core$Maybe$Just(75);
		case 'MYR':
			return $elm$core$Maybe$Just(76);
		case 'MZN':
			return $elm$core$Maybe$Just(77);
		case 'NAD':
			return $elm$core$Maybe$Just(78);
		case 'NGN':
			return $elm$core$Maybe$Just(79);
		case 'NIO':
			return $elm$core$Maybe$Just(80);
		case 'NOK':
			return $elm$core$Maybe$Just(81);
		case 'NPR':
			return $elm$core$Maybe$Just(82);
		case 'NZD':
			return $elm$core$Maybe$Just(83);
		case 'OMR':
			return $elm$core$Maybe$Just(84);
		case 'PAB':
			return $elm$core$Maybe$Just(85);
		case 'PEN':
			return $elm$core$Maybe$Just(86);
		case 'PHP':
			return $elm$core$Maybe$Just(87);
		case 'PKR':
			return $elm$core$Maybe$Just(88);
		case 'PLN':
			return $elm$core$Maybe$Just(89);
		case 'PYG':
			return $elm$core$Maybe$Just(90);
		case 'QAR':
			return $elm$core$Maybe$Just(91);
		case 'RON':
			return $elm$core$Maybe$Just(92);
		case 'RSD':
			return $elm$core$Maybe$Just(93);
		case 'RUB':
			return $elm$core$Maybe$Just(94);
		case 'RWF':
			return $elm$core$Maybe$Just(95);
		case 'SAR':
			return $elm$core$Maybe$Just(96);
		case 'SDG':
			return $elm$core$Maybe$Just(97);
		case 'SEK':
			return $elm$core$Maybe$Just(98);
		case 'SGD':
			return $elm$core$Maybe$Just(99);
		case 'SOS':
			return $elm$core$Maybe$Just(100);
		case 'SYP':
			return $elm$core$Maybe$Just(101);
		case 'THB':
			return $elm$core$Maybe$Just(102);
		case 'TND':
			return $elm$core$Maybe$Just(103);
		case 'TOP':
			return $elm$core$Maybe$Just(104);
		case 'TRY':
			return $elm$core$Maybe$Just(105);
		case 'TTD':
			return $elm$core$Maybe$Just(106);
		case 'TWD':
			return $elm$core$Maybe$Just(107);
		case 'TZS':
			return $elm$core$Maybe$Just(108);
		case 'UAH':
			return $elm$core$Maybe$Just(109);
		case 'UGX':
			return $elm$core$Maybe$Just(110);
		case 'UYU':
			return $elm$core$Maybe$Just(111);
		case 'UZS':
			return $elm$core$Maybe$Just(112);
		case 'VEF':
			return $elm$core$Maybe$Just(113);
		case 'VND':
			return $elm$core$Maybe$Just(114);
		case 'XAF':
			return $elm$core$Maybe$Just(115);
		case 'XOF':
			return $elm$core$Maybe$Just(116);
		case 'YER':
			return $elm$core$Maybe$Just(117);
		case 'ZAR':
			return $elm$core$Maybe$Just(118);
		case 'ZMK':
			return $elm$core$Maybe$Just(119);
		case 'AOA':
			return $elm$core$Maybe$Just(120);
		case 'XCD':
			return $elm$core$Maybe$Just(121);
		case 'AWG':
			return $elm$core$Maybe$Just(122);
		case 'BSD':
			return $elm$core$Maybe$Just(123);
		case 'BBD':
			return $elm$core$Maybe$Just(124);
		case 'BMD':
			return $elm$core$Maybe$Just(125);
		case 'BTN':
			return $elm$core$Maybe$Just(126);
		case 'KYD':
			return $elm$core$Maybe$Just(127);
		case 'CUP':
			return $elm$core$Maybe$Just(128);
		case 'ANG':
			return $elm$core$Maybe$Just(129);
		case 'SZL':
			return $elm$core$Maybe$Just(130);
		case 'FKP':
			return $elm$core$Maybe$Just(131);
		case 'FJD':
			return $elm$core$Maybe$Just(132);
		case 'XPF':
			return $elm$core$Maybe$Just(133);
		case 'GMD':
			return $elm$core$Maybe$Just(134);
		case 'GIP':
			return $elm$core$Maybe$Just(135);
		case 'GYD':
			return $elm$core$Maybe$Just(136);
		case 'HTG':
			return $elm$core$Maybe$Just(137);
		case 'KPW':
			return $elm$core$Maybe$Just(138);
		case 'KGS':
			return $elm$core$Maybe$Just(139);
		case 'LSL':
			return $elm$core$Maybe$Just(140);
		case 'LRD':
			return $elm$core$Maybe$Just(141);
		case 'MWK':
			return $elm$core$Maybe$Just(142);
		case 'MVR':
			return $elm$core$Maybe$Just(143);
		case 'MRU':
			return $elm$core$Maybe$Just(144);
		case 'MNT':
			return $elm$core$Maybe$Just(145);
		case 'PGK':
			return $elm$core$Maybe$Just(146);
		case 'SHP':
			return $elm$core$Maybe$Just(147);
		case 'WST':
			return $elm$core$Maybe$Just(148);
		case 'STN':
			return $elm$core$Maybe$Just(149);
		case 'SCR':
			return $elm$core$Maybe$Just(150);
		case 'SLL':
			return $elm$core$Maybe$Just(151);
		case 'SBD':
			return $elm$core$Maybe$Just(152);
		case 'SSP':
			return $elm$core$Maybe$Just(153);
		case 'SRD':
			return $elm$core$Maybe$Just(154);
		case 'TJS':
			return $elm$core$Maybe$Just(155);
		case 'TMT':
			return $elm$core$Maybe$Just(156);
		case 'VUV':
			return $elm$core$Maybe$Just(157);
		case 'VES':
			return $elm$core$Maybe$Just(158);
		case 'ZMW':
			return $elm$core$Maybe$Just(159);
		case 'ZWL':
			return $elm$core$Maybe$Just(160);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Bill$decodeCurrency = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		var _v0 = $Chadtech$elm_money$Money$fromString(code);
		if (!_v0.$) {
			var currency = _v0.a;
			return $elm$json$Json$Decode$succeed(currency);
		} else {
			return $elm$json$Json$Decode$fail('Unknown currency code ' + code);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Bill$LineItem = F3(
	function (total, product, description) {
		return {cb: description, cE: product, bK: total};
	});
var $author$project$Product$Product = F4(
	function (id, name, code, benefitValues) {
		return {b$: benefitValues, aU: code, ck: id, cu: name};
	});
var $author$project$Product$BenefitValue = F3(
	function (description, benefitCode, value) {
		return {b_: benefitCode, cb: description, cY: value};
	});
var $author$project$Product$BenefitCode = $elm$core$Basics$identity;
var $author$project$Product$benefitCodeDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Product$decodeBenefitValue = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'value',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'benefitCode',
		$author$project$Product$benefitCodeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'description',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Product$BenefitValue))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Product$ProductId = $elm$core$Basics$identity;
var $author$project$Product$productIdDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Product$decodeProduct = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'benefitValues',
	$elm$json$Json$Decode$list($author$project$Product$decodeBenefitValue),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'code',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$Product$productIdDecoder,
				$elm$json$Json$Decode$succeed($author$project$Product$Product)))));
var $author$project$Bill$decodeLineItem = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'description',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'product',
		$author$project$Product$decodeProduct,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'total',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Bill$LineItem))));
var $author$project$Bill$Tax = F2(
	function (kind, amount) {
		return {bW: amount, cp: kind};
	});
var $author$project$Bill$GST = 1;
var $author$project$Bill$StampDuty = 0;
var $author$project$Bill$decodeTaxKind = A2(
	$elm$json$Json$Decode$andThen,
	function (kind) {
		switch (kind) {
			case 'StampDuty':
				return $elm$json$Json$Decode$succeed(0);
			case 'GST':
				return $elm$json$Json$Decode$succeed(1);
			default:
				var unknown = kind;
				return $elm$json$Json$Decode$fail('Unknown tax kind ' + unknown);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Bill$decodeTax = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'amount',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'kind',
		$author$project$Bill$decodeTaxKind,
		$elm$json$Json$Decode$succeed($author$project$Bill$Tax)));
var $author$project$Bill$decodeBill = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'billingCycleOptions',
	$elm$json$Json$Decode$list($author$project$Bill$decodeBillingCycleOption),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'totalInclTax',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'total',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'taxes',
				$elm$json$Json$Decode$list($author$project$Bill$decodeTax),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'lineItems',
					$elm$json$Json$Decode$list($author$project$Bill$decodeLineItem),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'currency',
						$author$project$Bill$decodeCurrency,
						$elm$json$Json$Decode$succeed($author$project$Bill$Bill)))))));
var $author$project$Customer$Customer = F8(
	function (name, email, address1, city, postcode, state, country, phoneNumber) {
		return {aP: address1, aS: city, aW: country, aY: email, cu: name, bk: phoneNumber, bq: postcode, bG: state};
	});
var $author$project$Customer$decodeCustomer = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'phoneNumber',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'country',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'state',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'postcode',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'city',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'address1',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'email',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'name',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Customer$Customer)))))))));
var $author$project$QuoteItem$QuoteItem = F7(
	function (id, name, catalogueId, catalogueName, availablePremiums, selectedProduct, parameters) {
		return {bZ: availablePremiums, b5: catalogueId, b6: catalogueName, ck: id, cu: name, u: parameters, cJ: selectedProduct};
	});
var $author$project$Parameter$Parameter = F2(
	function (band, ratingFactor) {
		return {X: band, i: ratingFactor};
	});
var $author$project$Band$Band = F2(
	function (id, name) {
		return {ck: id, cu: name};
	});
var $author$project$Band$BandId = $elm$core$Basics$identity;
var $author$project$Band$idDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Band$decodeBand = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$author$project$Band$idDecoder,
		$elm$json$Json$Decode$succeed($author$project$Band$Band)));
var $author$project$RatingFactor$RatingFactor = F4(
	function (id, name, kind, bands) {
		return {ah: bands, ck: id, cp: kind, cu: name};
	});
var $author$project$RatingFactor$DaysRelative = 1;
var $author$project$RatingFactor$SetBand = 0;
var $author$project$RatingFactor$stringToKind = function (s) {
	switch (s) {
		case 'set_band':
			return $elm$core$Maybe$Just(0);
		case 'days_relative':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$RatingFactor$decodeKind = A2(
	$elm$json$Json$Decode$andThen,
	function (k) {
		var _v0 = $author$project$RatingFactor$stringToKind(k);
		if (!_v0.$) {
			var kind = _v0.a;
			return $elm$json$Json$Decode$succeed(kind);
		} else {
			return $elm$json$Json$Decode$fail('Unknown rating factor kind ' + k);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$RatingFactor$RatingFactorId = $elm$core$Basics$identity;
var $author$project$RatingFactor$idDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$RatingFactor$decodeRatingFactor = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'bands',
	$elm$json$Json$Decode$list($author$project$Band$decodeBand),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'kind',
		$author$project$RatingFactor$decodeKind,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$RatingFactor$idDecoder,
				$elm$json$Json$Decode$succeed($author$project$RatingFactor$RatingFactor)))));
var $author$project$Parameter$decodeParameter = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'ratingFactor',
	$author$project$RatingFactor$decodeRatingFactor,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'band',
		$author$project$Band$decodeBand,
		$elm$json$Json$Decode$succeed($author$project$Parameter$Parameter)));
var $author$project$QuoteItem$Premium = F4(
	function (product, billingCycleOptions, total, totalInclTax) {
		return {b1: billingCycleOptions, cE: product, bK: total, cU: totalInclTax};
	});
var $author$project$QuoteItem$decodePremium = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'totalInclTax',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'billingCycleOptions',
			$elm$json$Json$Decode$list($author$project$Bill$decodeBillingCycleOption),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'product',
				$author$project$Product$decodeProduct,
				$elm$json$Json$Decode$succeed($author$project$QuoteItem$Premium)))));
var $author$project$Catalogue$CatalogueId = $elm$core$Basics$identity;
var $author$project$Catalogue$idDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$QuoteItem$QuoteItemId = $elm$core$Basics$identity;
var $author$project$QuoteItem$quoteItemIdDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$QuoteItem$decodeQuoteItem = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parameters',
	$elm$json$Json$Decode$list($author$project$Parameter$decodeParameter),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'selectedProduct',
		$elm$json$Json$Decode$nullable($author$project$Product$decodeProduct),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'availablePremiums',
			$elm$json$Json$Decode$list($author$project$QuoteItem$decodePremium),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'catalogueName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'catalogueId',
					$author$project$Catalogue$idDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$author$project$QuoteItem$quoteItemIdDecoder,
							$elm$json$Json$Decode$succeed($author$project$QuoteItem$QuoteItem))))))));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Quote$decodeTime = A2(
	$elm$json$Json$Decode$andThen,
	function (ms) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(ms * 1000));
	},
	$elm$json$Json$Decode$int);
var $author$project$Policy$PolicyId = $elm$core$Basics$identity;
var $author$project$Policy$policyIdDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Quote$QuoteId = $elm$core$Basics$identity;
var $author$project$Quote$quoteIdDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Quote$decodeQuote = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'billingCycle',
	$elm$json$Json$Decode$nullable($author$project$Bill$decodeBillingCycleUnit),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'startsAt',
		$elm$json$Json$Decode$nullable($author$project$Quote$decodeTime),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'policyId',
			$elm$json$Json$Decode$nullable($author$project$Policy$policyIdDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'customer',
				$author$project$Customer$decodeCustomer,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'bill',
					$author$project$Bill$decodeBill,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'quoteItems',
						$elm$json$Json$Decode$list($author$project$QuoteItem$decodeQuoteItem),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'currency',
							$author$project$Bill$decodeCurrency,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'number',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$author$project$Quote$quoteIdDecoder,
									$elm$json$Json$Decode$succeed($author$project$Quote$Quote))))))))));
var $author$project$ErrorDetailed$BadBody = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$ErrorDetailed$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$ErrorDetailed$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$ErrorDetailed$NetworkError = {$: 2};
var $author$project$ErrorDetailed$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$ErrorDetailed$convertResponseStringToJson = F2(
	function (decoder, httpResponse) {
		switch (httpResponse.$) {
			case 0:
				var url = httpResponse.a;
				return $elm$core$Result$Err(
					$author$project$ErrorDetailed$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($author$project$ErrorDetailed$Timeout);
			case 2:
				return $elm$core$Result$Err($author$project$ErrorDetailed$NetworkError);
			case 3:
				var metadata = httpResponse.a;
				var body = httpResponse.b;
				return $elm$core$Result$Err(
					A2($author$project$ErrorDetailed$BadStatus, metadata, body));
			default:
				var metadata = httpResponse.a;
				var body = httpResponse.b;
				return A2(
					$elm$core$Result$mapError,
					A2($author$project$ErrorDetailed$BadBody, metadata, body),
					A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2(
							$elm$json$Json$Decode$decodeString,
							A2(
								$elm$json$Json$Decode$map,
								function (res) {
									return _Utils_Tuple2(metadata, res);
								},
								decoder),
							body)));
		}
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$ErrorDetailed$expectJsonDetailed = F2(
	function (msg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			msg,
			$author$project$ErrorDetailed$convertResponseStringToJson(decoder));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {bx: reqs, bI: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.cV;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.bx));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.bI)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					bV: r.bV,
					b2: r.b2,
					a1: A2(_Http_mapExpect, func, r.a1),
					ci: r.ci,
					cs: r.cs,
					cS: r.cS,
					cV: r.cV,
					bM: r.bM
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{bV: false, b2: r.b2, a1: r.a1, ci: r.ci, cs: r.cs, cS: r.cS, cV: r.cV, bM: r.bM}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{b2: $elm$http$Http$emptyBody, a1: r.a1, ci: _List_Nil, cs: 'GET', cS: $elm$core$Maybe$Nothing, cV: $elm$core$Maybe$Nothing, bM: r.bM});
};
var $author$project$Quote$quoteIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Page$EditQuote$fetchQuote = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$EditQuote$GotQuote, $author$project$Quote$decodeQuote),
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(id) + '.json')
		});
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cL, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		aX: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		_: month,
		ag: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).aX;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time))._;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ag;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$Page$EditQuote$init = F2(
	function (quoteId, navKey) {
		return _Utils_Tuple2(
			{y: navKey, al: $elm$core$Maybe$Nothing, f: $elm$core$Maybe$Nothing, ab: $elm$core$Maybe$Nothing, m: $author$project$Page$EditQuote$Initialising, as: $elm$core$Maybe$Nothing},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Page$EditQuote$ReceiveDate, $justinmimbs$date$Date$today),
						$author$project$Page$EditQuote$fetchQuote(quoteId)
					])));
	});
var $author$project$Page$NewQuote$Initialising = {$: 0};
var $author$project$Page$NewQuote$GotCatalogues = function (a) {
	return {$: 0, a: a};
};
var $author$project$Catalogue$Catalogue = F4(
	function (id, name, brand, ratingFactors) {
		return {b3: brand, ck: id, cu: name, cG: ratingFactors};
	});
var $author$project$Brand$Brand = F3(
	function (id, name, publicStripeApiKey) {
		return {ck: id, cu: name, cF: publicStripeApiKey};
	});
var $author$project$Brand$BrandId = $elm$core$Basics$identity;
var $author$project$Brand$idDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Brand$decodeBrand = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'publicStripeApiKey',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$author$project$Brand$idDecoder,
			$elm$json$Json$Decode$succeed($author$project$Brand$Brand))));
var $author$project$Catalogue$decodeCatalogue = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'ratingFactors',
	$elm$json$Json$Decode$list($author$project$RatingFactor$decodeRatingFactor),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'brand',
		$author$project$Brand$decodeBrand,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$author$project$Catalogue$idDecoder,
				$elm$json$Json$Decode$succeed($author$project$Catalogue$Catalogue)))));
var $author$project$Page$NewQuote$fetchCatalogues = $elm$http$Http$get(
	{
		a1: A2(
			$author$project$ErrorDetailed$expectJsonDetailed,
			$author$project$Page$NewQuote$GotCatalogues,
			$elm$json$Json$Decode$list($author$project$Catalogue$decodeCatalogue)),
		bM: '/api/v1/catalogues.json'
	});
var $author$project$Page$NewQuote$init = function (navKey) {
	return _Utils_Tuple2(
		{P: $elm$core$Maybe$Nothing, y: navKey, B: 1, h: _List_Nil, bG: $elm$core$Maybe$Nothing, m: $author$project$Page$NewQuote$Initialising},
		$author$project$Page$NewQuote$fetchCatalogues);
};
var $author$project$Page$PayQuote$Loading = {$: 0};
var $author$project$Page$PayQuote$ReceiveDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PayQuote$GotQuote = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PayQuote$fetchQuote = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$PayQuote$GotQuote, $author$project$Quote$decodeQuote),
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(id) + '.json')
		});
};
var $author$project$Page$PayQuote$init = F2(
	function (quoteId, navKey) {
		var initialEvent = {aj: false, aZ: true, av: $elm$core$Maybe$Nothing};
		return _Utils_Tuple2(
			{
				O: '',
				s: $author$project$Page$PayQuote$Loading,
				S: $elm$core$Maybe$Just(initialEvent),
				y: navKey,
				J: 0,
				aH: $elm$core$Maybe$Nothing,
				f: $elm$core$Maybe$Nothing,
				as: $elm$core$Maybe$Nothing
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Page$PayQuote$ReceiveDate, $justinmimbs$date$Date$today),
						$author$project$Page$PayQuote$fetchQuote(quoteId)
					])));
	});
var $author$project$Page$PolicyBilling$Ready = {$: 1};
var $author$project$Page$PolicyBilling$GotPolicy = function (a) {
	return {$: 0, a: a};
};
var $author$project$Policy$Policy = function (id) {
	return function (brand) {
		return function (currency) {
			return function (customer) {
				return function (number) {
					return function (periodOfInsurance) {
						return function (payments) {
							return function (policyLines) {
								return function (bill) {
									return function (status) {
										return function (billingCycle) {
											return {N: bill, b0: billingCycle, b3: brand, ca: currency, e: customer, ck: id, cv: number, cB: payments, cC: periodOfInsurance, bm: policyLines, m: status};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Payment$Payment = F8(
	function (amount, status, currency, paidAt, attemptedAt, paymentMethod, stripeReceiptUrl, clientSecret) {
		return {bW: amount, bY: attemptedAt, aT: clientSecret, ca: currency, cz: paidAt, bj: paymentMethod, m: status, cO: stripeReceiptUrl};
	});
var $author$project$Payment$decodeCurrency = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		var _v0 = $Chadtech$elm_money$Money$fromString(code);
		if (!_v0.$) {
			var currency = _v0.a;
			return $elm$json$Json$Decode$succeed(currency);
		} else {
			return $elm$json$Json$Decode$fail('Unknown currency code ' + code);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Payment$PaymentMethod = F4(
	function (brand, expMonth, expYear, last4) {
		return {b3: brand, ce: expMonth, cf: expYear, cq: last4};
	});
var $author$project$Payment$decodePaymentMethod = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'last4',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'expYear',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'expMonth',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'brand',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Payment$PaymentMethod)))));
var $author$project$Payment$Failed = 1;
var $author$project$Payment$Success = 0;
var $author$project$Payment$stringToPaymentStatus = function (s) {
	switch (s) {
		case 'success':
			return $elm$core$Maybe$Just(0);
		case 'failed':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Payment$decodePaymentStatus = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $author$project$Payment$stringToPaymentStatus(s);
		if (!_v0.$) {
			var status = _v0.a;
			return $elm$json$Json$Decode$succeed(status);
		} else {
			return $elm$json$Json$Decode$fail('Unknown payment status ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Payment$decodeTime = A2(
	$elm$json$Json$Decode$andThen,
	function (ms) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(ms * 1000));
	},
	$elm$json$Json$Decode$int);
var $author$project$Payment$decodePayment = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'clientSecret',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'stripeReceiptUrl',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'paymentMethod',
			$author$project$Payment$decodePaymentMethod,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'attemptedAt',
				$author$project$Payment$decodeTime,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'paidAt',
					$elm$json$Json$Decode$nullable($author$project$Payment$decodeTime),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'currency',
						$author$project$Payment$decodeCurrency,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'status',
							$author$project$Payment$decodePaymentStatus,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'amount',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Payment$Payment)))))))));
var $author$project$PolicyLine$PolicyLine = F5(
	function (name, catalogueName, total, product, parameters) {
		return {b6: catalogueName, cu: name, u: parameters, cE: product, bK: total};
	});
var $author$project$PolicyLine$decodePolicyLine = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parameters',
	$elm$json$Json$Decode$list($author$project$Parameter$decodeParameter),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'product',
		$author$project$Product$decodeProduct,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'total',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'catalogueName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PolicyLine$PolicyLine))))));
var $author$project$Policy$Active = 1;
var $author$project$Policy$Cancelled = 3;
var $author$project$Policy$Imminent = 0;
var $author$project$Policy$Lapsed = 2;
var $author$project$Policy$stringToStatus = function (s) {
	switch (s) {
		case 'imminent':
			return $elm$core$Maybe$Just(0);
		case 'active':
			return $elm$core$Maybe$Just(1);
		case 'lapsed':
			return $elm$core$Maybe$Just(2);
		case 'cancelled':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Policy$decodeStatus = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $author$project$Policy$stringToStatus(s);
		if (!_v0.$) {
			var status = _v0.a;
			return $elm$json$Json$Decode$succeed(status);
		} else {
			return $elm$json$Json$Decode$fail('Unknown policy status ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Policy$PolicyNumber = $elm$core$Basics$identity;
var $author$project$Policy$policyNumberDecoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Policy$TimeRange = F2(
	function (start, finish) {
		return {ch: finish, cL: start};
	});
var $author$project$Policy$decodeTime = A2(
	$elm$json$Json$Decode$andThen,
	function (ms) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(ms * 1000));
	},
	$elm$json$Json$Decode$int);
var $author$project$Policy$timeRangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'finish',
	$author$project$Policy$decodeTime,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'start',
		$author$project$Policy$decodeTime,
		$elm$json$Json$Decode$succeed($author$project$Policy$TimeRange)));
var $author$project$Policy$decodePolicy = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'billingCycle',
	$author$project$Bill$decodeBillingCycleUnit,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$author$project$Policy$decodeStatus,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'bill',
			$author$project$Bill$decodeBill,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'policyLines',
				$elm$json$Json$Decode$list($author$project$PolicyLine$decodePolicyLine),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'payments',
					$elm$json$Json$Decode$list($author$project$Payment$decodePayment),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'periodOfInsurance',
						$author$project$Policy$timeRangeDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'number',
							$author$project$Policy$policyNumberDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'customer',
								$author$project$Customer$decodeCustomer,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'currency',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'brand',
										$author$project$Brand$decodeBrand,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$author$project$Policy$policyIdDecoder,
											$elm$json$Json$Decode$succeed($author$project$Policy$Policy))))))))))));
var $author$project$Policy$policyIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Page$PolicyBilling$fetchPolicy = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$PolicyBilling$GotPolicy, $author$project$Policy$decodePolicy),
			bM: '/api/v1/policies/' + ($author$project$Policy$policyIdToString(id) + '.json')
		});
};
var $author$project$Page$PolicyBilling$init = F2(
	function (policyId, navKey) {
		var initialEvent = {aj: false, aZ: true, av: $elm$core$Maybe$Nothing};
		return _Utils_Tuple2(
			{
				O: '',
				s: $author$project$Page$PolicyBilling$Ready,
				S: $elm$core$Maybe$Just(initialEvent),
				y: navKey,
				C: $elm$core$Maybe$Nothing
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Page$PolicyBilling$fetchPolicy(policyId)
					])));
	});
var $author$project$Page$ShowPolicy$GotPolicy = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ShowPolicy$fetchPolicy = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$ShowPolicy$GotPolicy, $author$project$Policy$decodePolicy),
			bM: '/api/v1/policies/' + ($author$project$Policy$policyIdToString(id) + '.json')
		});
};
var $author$project$Page$ShowPolicy$init = F2(
	function (id, navKey) {
		return _Utils_Tuple2(
			{Q: $elm$core$Maybe$Nothing, y: navKey, C: $elm$core$Maybe$Nothing, J: 1},
			$author$project$Page$ShowPolicy$fetchPolicy(id));
	});
var $author$project$Page$ShowQuote$Initialising = {$: 0};
var $author$project$Page$ShowQuote$GotQuote = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ShowQuote$fetchQuote = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$ShowQuote$GotQuote, $author$project$Quote$decodeQuote),
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(id) + '.json')
		});
};
var $author$project$Page$ShowQuote$init = F2(
	function (quoteId, navKey) {
		return _Utils_Tuple2(
			{V: 0, y: navKey, f: $elm$core$Maybe$Nothing, m: $author$project$Page$ShowQuote$Initialising},
			$author$project$Page$ShowQuote$fetchQuote(quoteId));
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$initCurrentPage = function (_v0) {
	var model = _v0.a;
	var existingCmds = _v0.b;
	var _v1 = function () {
		var _v2 = model.an;
		switch (_v2.$) {
			case 0:
				return _Utils_Tuple2($author$project$Main$NotFoundPage, $elm$core$Platform$Cmd$none);
			case 1:
				var _v3 = $author$project$Page$NewQuote$init(model.y);
				var pageModel = _v3.a;
				var pageCmds = _v3.b;
				return _Utils_Tuple2(
					$author$project$Main$NewQuotePage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$NewQuotePageMsg, pageCmds));
			case 2:
				var id = _v2.a;
				var _v4 = A2($author$project$Page$ShowQuote$init, id, model.y);
				var pageModel = _v4.a;
				var pageCmds = _v4.b;
				return _Utils_Tuple2(
					$author$project$Main$ShowQuotePage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$ShowQuotePageMsg, pageCmds));
			case 3:
				var id = _v2.a;
				var _v5 = A2($author$project$Page$EditQuote$init, id, model.y);
				var pageModel = _v5.a;
				var pageCmds = _v5.b;
				return _Utils_Tuple2(
					$author$project$Main$EditQuotePage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$EditQuotePageMsg, pageCmds));
			case 4:
				var id = _v2.a;
				var _v6 = A2($author$project$Page$PayQuote$init, id, model.y);
				var pageModel = _v6.a;
				var pageCmds = _v6.b;
				return _Utils_Tuple2(
					$author$project$Main$PayQuotePage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$PayQuotePageMsg, pageCmds));
			case 5:
				var id = _v2.a;
				var _v7 = A2($author$project$Page$ShowPolicy$init, id, model.y);
				var pageModel = _v7.a;
				var pageCmds = _v7.b;
				return _Utils_Tuple2(
					$author$project$Main$ShowPolicyPage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$ShowPolicyPageMsg, pageCmds));
			default:
				var id = _v2.a;
				var _v8 = A2($author$project$Page$PolicyBilling$init, id, model.y);
				var pageModel = _v8.a;
				var pageCmds = _v8.b;
				return _Utils_Tuple2(
					$author$project$Main$PolicyBillingPage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$PolicyBillingPageMsg, pageCmds));
		}
	}();
	var currentPage = _v1.a;
	var mappedPageCmds = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{r: currentPage}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[existingCmds, mappedPageCmds])));
};
var $author$project$Route$NotFound = {$: 0};
var $author$project$Route$EditQuote = function (a) {
	return {$: 3, a: a};
};
var $author$project$Route$NewQuote = {$: 1};
var $author$project$Route$PayQuote = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$PolicyBilling = function (a) {
	return {$: 6, a: a};
};
var $author$project$Route$ShowPolicy = function (a) {
	return {$: 5, a: a};
};
var $author$project$Route$ShowQuote = function (a) {
	return {$: 2, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {H: frag, I: params, F: unvisited, cY: value, L: visited};
	});
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.L;
		var unvisited = _v0.F;
		var params = _v0.I;
		var frag = _v0.H;
		var value = _v0.cY;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.L;
			var unvisited = _v1.F;
			var params = _v1.I;
			var frag = _v1.H;
			var value = _v1.cY;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.L;
			var unvisited = _v0.F;
			var params = _v0.I;
			var frag = _v0.H;
			var value = _v0.cY;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $author$project$Route$policyIdParser = A2(
	$elm$url$Url$Parser$custom,
	'POLICYID',
	function (id) {
		return $elm$core$Maybe$Just(id);
	});
var $author$project$Route$quoteIdParser = A2(
	$elm$url$Url$Parser$custom,
	'QUOTEID',
	function (id) {
		return $elm$core$Maybe$Just(id);
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.L;
		var unvisited = _v0.F;
		var params = _v0.I;
		var frag = _v0.H;
		var value = _v0.cY;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$matchRoute = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$NewQuote, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ShowQuote,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quote'),
				$author$project$Route$quoteIdParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditQuote,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quote'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Route$quoteIdParser,
					$elm$url$Url$Parser$s('details')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PayQuote,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quote'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Route$quoteIdParser,
					$elm$url$Url$Parser$s('pay')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ShowPolicy,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('policy'),
				$author$project$Route$policyIdParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PolicyBilling,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('policy'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Route$policyIdParser,
					$elm$url$Url$Parser$s('billing'))))
		]));
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.F;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.cY);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.cY);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.bi),
					$elm$url$Url$Parser$prepareQuery(url.bv),
					url.a5,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$parseUrl = function (url) {
	var _v0 = A2($elm$url$Url$Parser$parse, $author$project$Route$matchRoute, url);
	if (_v0.$ === 1) {
		return $author$project$Route$NotFound;
	} else {
		var route = _v0.a;
		return route;
	}
};
var $author$project$Main$init = F3(
	function (_v0, url, navKey) {
		var model = {
			y: navKey,
			r: $author$project$Main$NotFoundPage,
			an: $author$project$Route$parseUrl(url)
		};
		return $author$project$Main$initCurrentPage(
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Page$PayQuote$StripeFormChange = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PayQuote$StripeFormReady = {$: 4};
var $author$project$Page$PayQuote$StripeSetupFailed = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PayQuote$StripeSetupSucceeded = {$: 6};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Page$PayQuote$stripeFormChange = _Platform_incomingPort('stripeFormChange', $elm$json$Json$Decode$value);
var $author$project$Page$PayQuote$stripeFormReady = _Platform_incomingPort(
	'stripeFormReady',
	$elm$json$Json$Decode$null(0));
var $author$project$Page$PayQuote$stripeSetupFailed = _Platform_incomingPort('stripeSetupFailed', $elm$json$Json$Decode$string);
var $author$project$Page$PayQuote$stripeSetupSucceeded = _Platform_incomingPort(
	'stripeSetupSucceeded',
	$elm$json$Json$Decode$null(0));
var $author$project$Page$PayQuote$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$PayQuote$stripeFormReady(
			$elm$core$Basics$always($author$project$Page$PayQuote$StripeFormReady)),
			$author$project$Page$PayQuote$stripeFormChange($author$project$Page$PayQuote$StripeFormChange),
			$author$project$Page$PayQuote$stripeSetupSucceeded(
			$elm$core$Basics$always($author$project$Page$PayQuote$StripeSetupSucceeded)),
			$author$project$Page$PayQuote$stripeSetupFailed($author$project$Page$PayQuote$StripeSetupFailed)
		]));
var $author$project$Page$PolicyBilling$StripePaymentMethodFailed = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PolicyBilling$StripePaymentMethodFormChange = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PolicyBilling$StripePaymentMethodFormReady = {$: 2};
var $author$project$Page$PolicyBilling$StripePaymentMethodSucceeded = {$: 4};
var $author$project$Page$PolicyBilling$stripePaymentMethodFailed = _Platform_incomingPort('stripePaymentMethodFailed', $elm$json$Json$Decode$string);
var $author$project$Page$PolicyBilling$stripePaymentMethodFormChange = _Platform_incomingPort('stripePaymentMethodFormChange', $elm$json$Json$Decode$value);
var $author$project$Page$PolicyBilling$stripePaymentMethodFormReady = _Platform_incomingPort(
	'stripePaymentMethodFormReady',
	$elm$json$Json$Decode$null(0));
var $author$project$Page$PolicyBilling$stripePaymentMethodSucceeded = _Platform_incomingPort(
	'stripePaymentMethodSucceeded',
	$elm$json$Json$Decode$null(0));
var $author$project$Page$PolicyBilling$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$PolicyBilling$stripePaymentMethodFormReady(
			$elm$core$Basics$always($author$project$Page$PolicyBilling$StripePaymentMethodFormReady)),
			$author$project$Page$PolicyBilling$stripePaymentMethodFormChange($author$project$Page$PolicyBilling$StripePaymentMethodFormChange),
			$author$project$Page$PolicyBilling$stripePaymentMethodSucceeded(
			$elm$core$Basics$always($author$project$Page$PolicyBilling$StripePaymentMethodSucceeded)),
			$author$project$Page$PolicyBilling$stripePaymentMethodFailed($author$project$Page$PolicyBilling$StripePaymentMethodFailed)
		]));
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$core$Platform$Sub$map, $author$project$Main$PayQuotePageMsg, $author$project$Page$PayQuote$subscriptions),
				A2($elm$core$Platform$Sub$map, $author$project$Main$PolicyBillingPageMsg, $author$project$Page$PolicyBilling$subscriptions)
			]));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.bu;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.a5,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.bv,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.bn,
					_Utils_ap(http, url.a8)),
				url.bi)));
};
var $author$project$Page$EditQuote$Error = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$EditQuote$Initialised = {$: 1};
var $author$project$Page$EditQuote$Saving = {$: 3};
var $author$project$Page$EditQuote$Success = {$: 4};
var $justinmimbs$time_extra$Time$Extra$Parts = F7(
	function (year, month, day, hour, minute, second, millisecond) {
		return {aX: day, ax: hour, az: millisecond, aA: minute, _: month, aJ: second, ag: year};
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {aX: d, _: m, ag: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		aF: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		ag: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.ag, 0, date.aF);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.aX;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $._;
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.ag;
		var month = _v0._;
		var day = _v0.aX;
		var hour = _v0.ax;
		var minute = _v0.aA;
		var second = _v0.aJ;
		var millisecond = _v0.az;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$EditQuote$dateToTime = function (date) {
	var y = $justinmimbs$date$Date$year(date);
	var m = $justinmimbs$date$Date$month(date);
	var d = $justinmimbs$date$Date$day(date);
	return A2(
		$justinmimbs$time_extra$Time$Extra$partsToPosix,
		$elm$time$Time$utc,
		A7($justinmimbs$time_extra$Time$Extra$Parts, y, m, d, 0, 0, 0, 0));
};
var $justinmimbs$date$Date$fromRataDie = function (rd) {
	return rd;
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Route$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/not-found';
		case 1:
			return '/';
		case 2:
			var id = route.a;
			return '/quote/' + $author$project$Quote$quoteIdToString(id);
		case 3:
			var id = route.a;
			return '/quote/' + ($author$project$Quote$quoteIdToString(id) + '/details');
		case 4:
			var id = route.a;
			return '/quote/' + ($author$project$Quote$quoteIdToString(id) + '/pay');
		case 5:
			var id = route.a;
			return '/policy/' + $author$project$Policy$policyIdToString(id);
		default:
			var id = route.a;
			return '/policy/' + ($author$project$Policy$policyIdToString(id) + '/billing');
	}
};
var $author$project$Route$pushUrl = F2(
	function (route, navKey) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			navKey,
			$author$project$Route$routeToString(route));
	});
var $author$project$Page$EditQuote$Dirty = {$: 2};
var $author$project$Page$EditQuote$updateCustomer = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				var _v1 = model.f;
				if (_v1.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v1.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{cu: name});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var email = msg.a;
				var _v2 = model.f;
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v2.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{aY: email});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var address = msg.a;
				var _v3 = model.f;
				if (_v3.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v3.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{aP: address});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var city = msg.a;
				var _v4 = model.f;
				if (_v4.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v4.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{aS: city});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var state = msg.a;
				var _v5 = model.f;
				if (_v5.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v5.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{bG: state});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var postcode = msg.a;
				var _v6 = model.f;
				if (_v6.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v6.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{bq: postcode});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var phone = msg.a;
				var _v7 = model.f;
				if (_v7.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v7.a;
					var customer = quote.e;
					var newCustomer = _Utils_update(
						customer,
						{bk: phone});
					var newQuote = _Utils_update(
						quote,
						{e: newCustomer});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$EditQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$EditQuote$UpdatedQuote = function (a) {
	return {$: 6, a: a};
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Customer$encodeCustomer = function (customer) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(customer.cu)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(customer.aY)),
				_Utils_Tuple2(
				'address1',
				$elm$json$Json$Encode$string(customer.aP)),
				_Utils_Tuple2(
				'city',
				$elm$json$Json$Encode$string(customer.aS)),
				_Utils_Tuple2(
				'postcode',
				$elm$json$Json$Encode$string(customer.bq)),
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(customer.bG)),
				_Utils_Tuple2(
				'country',
				$elm$json$Json$Encode$string(customer.aW)),
				_Utils_Tuple2(
				'phoneNumber',
				$elm$json$Json$Encode$string(customer.bk))
			]));
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Quote$encodeMaybeTime = function (time) {
	if (!time.$) {
		var t = time.a;
		var ms = $elm$time$Time$posixToMillis(t);
		var s = (ms / 1000) | 0;
		return $elm$json$Json$Encode$int(s);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$QuoteItem$itemName = function (name) {
	if (name.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var n = name.a;
		return $elm$json$Json$Encode$string(n);
	}
};
var $author$project$QuoteItem$quoteItemIdToString = function (_v0) {
	var uuid = _v0;
	return uuid;
};
var $author$project$Product$productIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$QuoteItem$selectedProductId = function (selected) {
	if (selected.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var product = selected.a;
		return $elm$json$Json$Encode$string(
			$author$project$Product$productIdToString(product.ck));
	}
};
var $author$project$QuoteItem$encodeQuoteItem = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(
					$author$project$QuoteItem$quoteItemIdToString(item.ck))),
				_Utils_Tuple2(
				'name',
				$author$project$QuoteItem$itemName(item.cu)),
				_Utils_Tuple2(
				'selectedProductId',
				$author$project$QuoteItem$selectedProductId(item.cJ))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Quote$encodeQuote = function (quote) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quoteItems',
				A2($elm$json$Json$Encode$list, $author$project$QuoteItem$encodeQuoteItem, quote.h)),
				_Utils_Tuple2(
				'customer',
				$author$project$Customer$encodeCustomer(quote.e)),
				_Utils_Tuple2(
				'startsAt',
				$author$project$Quote$encodeMaybeTime(quote.ao))
			]));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Page$EditQuote$updateQuote = function (quote) {
	return $elm$http$Http$request(
		{
			b2: $elm$http$Http$jsonBody(
				$author$project$Quote$encodeQuote(quote)),
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$EditQuote$UpdatedQuote, $author$project$Quote$decodeQuote),
			ci: _List_Nil,
			cs: 'PATCH',
			cS: $elm$core$Maybe$Nothing,
			cV: $elm$core$Maybe$Nothing,
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(quote.ck) + '.json')
		});
};
var $author$project$Page$EditQuote$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var quote = _v2.b;
					var _v3 = quote.cD;
					if (!_v3.$) {
						var id = _v3.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$Route$pushUrl,
								$author$project$Route$ShowPolicy(id),
								model.y));
					} else {
						var w = A2(
							$elm$core$Maybe$map,
							$justinmimbs$date$Date$fromPosix($elm$time$Time$utc),
							quote.ao);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									al: $elm$core$Maybe$Just(quote.e.bG),
									f: $elm$core$Maybe$Just(quote),
									ab: w,
									m: $author$project$Page$EditQuote$Initialised
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 1:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							as: $elm$core$Maybe$Just(date)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var s = msg.a;
				var _v4 = _Utils_Tuple2(
					model.f,
					$elm$core$String$toInt(s));
				if ((!_v4.a.$) && (!_v4.b.$)) {
					var q = _v4.a.a;
					var rd = _v4.b.a;
					var newQuote = _Utils_update(
						q,
						{ao: $elm$core$Maybe$Nothing});
					var d = $justinmimbs$date$Date$fromRataDie(rd);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								ab: $elm$core$Maybe$Just(d)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var s = msg.a;
				var _v5 = _Utils_Tuple2(
					model.f,
					$elm$core$String$toInt(s));
				if ((!_v5.a.$) && (!_v5.b.$)) {
					var q = _v5.a.a;
					var rd = _v5.b.a;
					var d = $justinmimbs$date$Date$fromRataDie(rd);
					var t = $author$project$Page$EditQuote$dateToTime(d);
					var newQuote = _Utils_update(
						q,
						{
							ao: $elm$core$Maybe$Just(t)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var subMsg = msg.a;
				return A2($author$project$Page$EditQuote$updateCustomer, subMsg, model);
			case 5:
				var _v6 = model.f;
				if (_v6.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v6.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{m: $author$project$Page$EditQuote$Saving}),
						$author$project$Page$EditQuote$updateQuote(quote));
				}
			default:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v8 = httpResponse.a;
					var quote = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(quote),
								m: $author$project$Page$EditQuote$Success
							}),
						A2(
							$author$project$Route$pushUrl,
							$author$project$Route$PayQuote(quote.ck),
							model.y));
				} else {
					var error = httpResponse.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: $author$project$Page$EditQuote$Error(error)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$NewQuote$Dirty = {$: 2};
var $author$project$Page$NewQuote$Error = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$NewQuote$Initialised = {$: 1};
var $author$project$Page$NewQuote$Saving = {$: 3};
var $author$project$Page$NewQuote$Success = {$: 4};
var $author$project$Band$bandIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Catalogue$catalogueIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Page$NewQuote$CreatedQuote = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$NewQuote$encodeNewCustomer = function (state) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(state))
			]));
};
var $author$project$Page$NewQuote$encodeMaybeBandId = function (band) {
	if (!band.$) {
		var b = band.a;
		return $elm$json$Json$Encode$string(
			$author$project$Band$bandIdToString(b.ck));
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Page$NewQuote$encodeMaybeTimestamp = function (time) {
	if (!time.$) {
		var t = time.a;
		return $elm$json$Json$Encode$string(t);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.aF;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		bO: 1 + (((rd - week1Day1) / 7) | 0),
		bP: wy,
		c_: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.bO;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.bP;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.ak(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.aB(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.ak(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.au(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.M(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.M(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.M(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.aM(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.M(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.M(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {aV: col, b8: contextStack, br: problem, bB: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.bB, s.aV, x, s.c));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.bB, s.aV, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{aV: newCol, c: s.c, d: s.d, b: newOffset, bB: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{aV: 1, c: s.c, d: s.d, b: s.b + 1, bB: s.bB + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{aV: s.aV + 1, c: s.c, d: s.d, b: newOffset, bB: s.bB, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{aV: col, c: s0.c, d: s0.d, b: offset, bB: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.bB, s.aV, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {aV: col, br: problem, bB: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.bB, p.aV, p.br);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{aV: 1, c: _List_Nil, d: 1, b: 0, bB: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	au: $justinmimbs$date$Date$withOrdinalSuffix,
	aB: $justinmimbs$date$Date$monthToName,
	ak: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	aM: $justinmimbs$date$Date$weekdayToName,
	M: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Page$NewQuote$formatTimestamp = function (d) {
	return $justinmimbs$date$Date$toIsoString(d) + 'T00:00:00+00:00';
};
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$NewQuote$intToMonth = function (i) {
	switch (i) {
		case 1:
			return $elm$core$Maybe$Just(0);
		case 2:
			return $elm$core$Maybe$Just(1);
		case 3:
			return $elm$core$Maybe$Just(2);
		case 4:
			return $elm$core$Maybe$Just(3);
		case 5:
			return $elm$core$Maybe$Just(4);
		case 6:
			return $elm$core$Maybe$Just(5);
		case 7:
			return $elm$core$Maybe$Just(6);
		case 8:
			return $elm$core$Maybe$Just(7);
		case 9:
			return $elm$core$Maybe$Just(8);
		case 10:
			return $elm$core$Maybe$Just(9);
		case 11:
			return $elm$core$Maybe$Just(10);
		case 12:
			return $elm$core$Maybe$Just(11);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$NewQuote$makeDate = F2(
	function (mm, yyyy) {
		var year = $elm$core$String$toInt(yyyy);
		var month = $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$NewQuote$intToMonth,
				$elm$core$String$toInt(mm)));
		var _v0 = _Utils_Tuple2(month, year);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var m = _v0.a.a;
			var y = _v0.b.a;
			return $elm$core$Maybe$Just(
				A3($justinmimbs$date$Date$fromCalendarDate, y, m, 1));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$RatingFactor$ratingFactorIdToString = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Page$NewQuote$encodeNewParameter = function (param) {
	var ratingFactor = _Utils_Tuple2(
		'ratingFactorId',
		$elm$json$Json$Encode$string(
			$author$project$RatingFactor$ratingFactorIdToString(param.i.ck)));
	var _v0 = param.i.cp;
	if (!_v0) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					ratingFactor,
					_Utils_Tuple2(
					'bandId',
					$author$project$Page$NewQuote$encodeMaybeBandId(param.X))
				]));
	} else {
		var t = A2(
			$elm$core$Maybe$map,
			$author$project$Page$NewQuote$formatTimestamp,
			$elm_community$maybe_extra$Maybe$Extra$join(
				A3($elm$core$Maybe$map2, $author$project$Page$NewQuote$makeDate, param._, param.ag)));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					ratingFactor,
					_Utils_Tuple2(
					'timestamp',
					$author$project$Page$NewQuote$encodeMaybeTimestamp(t))
				]));
	}
};
var $author$project$Page$NewQuote$encodeNewQuoteItem = function (quoteItem) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'catalogueId',
				$elm$json$Json$Encode$string(
					$author$project$Catalogue$catalogueIdToString(quoteItem.Y.ck))),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(quoteItem.cu)),
				_Utils_Tuple2(
				'parameters',
				A2($elm$json$Json$Encode$list, $author$project$Page$NewQuote$encodeNewParameter, quoteItem.u))
			]));
};
var $author$project$Page$NewQuote$encodeNewQuote = F2(
	function (quoteItems, state) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'quoteItems',
					A2($elm$json$Json$Encode$list, $author$project$Page$NewQuote$encodeNewQuoteItem, quoteItems)),
					_Utils_Tuple2(
					'customer',
					$author$project$Page$NewQuote$encodeNewCustomer(state))
				]));
	});
var $author$project$Page$NewQuote$createQuote = F2(
	function (quoteItems, state) {
		return $elm$http$Http$request(
			{
				b2: $elm$http$Http$jsonBody(
					A2($author$project$Page$NewQuote$encodeNewQuote, quoteItems, state)),
				a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$NewQuote$CreatedQuote, $author$project$Quote$decodeQuote),
				ci: _List_Nil,
				cs: 'POST',
				cS: $elm$core$Maybe$Nothing,
				cV: $elm$core$Maybe$Nothing,
				bM: '/api/v1/quotes.json'
			});
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Page$NewQuote$freshParameters = function (factors) {
	return A2(
		$elm$core$List$map,
		function (f) {
			return {X: $elm$core$Maybe$Nothing, _: $elm$core$Maybe$Nothing, i: f, ag: $elm$core$Maybe$Nothing};
		},
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.cu;
			},
			factors));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$NewQuote$quoteItemForCatalogue = F2(
	function (id, catalogue) {
		return {
			Y: catalogue,
			ck: id,
			cu: '',
			u: $author$project$Page$NewQuote$freshParameters(catalogue.cG)
		};
	});
var $author$project$Page$NewQuote$newQuoteItemForFirstCatalogue = F2(
	function (id, catalogues) {
		var _v0 = $elm$core$List$head(catalogues);
		if (!_v0.$) {
			var c = _v0.a;
			return _List_fromArray(
				[
					A2($author$project$Page$NewQuote$quoteItemForCatalogue, id, c)
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Page$NewQuote$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var catalogues = _v2.b;
					var items = A2($author$project$Page$NewQuote$newQuoteItemForFirstCatalogue, model.B, catalogues);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								P: $elm$core$Maybe$Just(catalogues),
								B: model.B + 1,
								h: items,
								m: $author$project$Page$NewQuote$Initialised
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var _v3 = model.P;
				if (_v3.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var catalogues = _v3.a;
					var newItem = A2($author$project$Page$NewQuote$newQuoteItemForFirstCatalogue, model.B, catalogues);
					var newItems = _Utils_ap(model.h, newItem);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{B: model.B + 1, h: newItems, m: $author$project$Page$NewQuote$Dirty}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var item = msg.a;
				var newItems = A2(
					$elm$core$List$filter,
					function (q) {
						return !_Utils_eq(q.ck, item.ck);
					},
					model.h);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newItems, m: $author$project$Page$NewQuote$Dirty}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var item = msg.a;
				var name = msg.b;
				var newQuoteItems = A2(
					$elm$core$List$map,
					function (i) {
						return _Utils_eq(i.ck, item.ck) ? _Utils_update(
							i,
							{cu: name}) : i;
					},
					model.h);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newQuoteItems, m: $author$project$Page$NewQuote$Dirty}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var item = msg.a;
				var id = msg.b;
				var _v4 = model.P;
				if (_v4.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var catalogues = _v4.a;
					var found = A2(
						$elm_community$list_extra$List$Extra$find,
						function (c) {
							return _Utils_eq(
								$author$project$Catalogue$catalogueIdToString(c.ck),
								id);
						},
						catalogues);
					if (!found.$) {
						var c = found.a;
						var newQuoteItem = _Utils_update(
							item,
							{
								Y: c,
								u: $author$project$Page$NewQuote$freshParameters(c.cG)
							});
						var newItems = A2(
							$elm$core$List$map,
							function (q) {
								return _Utils_eq(q.ck, item.ck) ? newQuoteItem : q;
							},
							model.h);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{h: newItems, m: $author$project$Page$NewQuote$Dirty}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 5:
				var item = msg.a;
				var param = msg.b;
				var bandId = msg.c;
				var foundBand = A2(
					$elm_community$list_extra$List$Extra$find,
					function (b) {
						return _Utils_eq(
							$author$project$Band$bandIdToString(b.ck),
							bandId);
					},
					param.i.ah);
				if (!foundBand.$) {
					var band = foundBand.a;
					var rfId = $author$project$RatingFactor$ratingFactorIdToString(param.i.ck);
					var newParam = _Utils_update(
						param,
						{
							X: $elm$core$Maybe$Just(band)
						});
					var newParams = A2(
						$elm$core$List$map,
						function (p) {
							return _Utils_eq(
								$author$project$RatingFactor$ratingFactorIdToString(p.i.ck),
								rfId) ? newParam : p;
						},
						item.u);
					var newQuoteItem = _Utils_update(
						item,
						{u: newParams});
					var newItems = A2(
						$elm$core$List$map,
						function (q) {
							return _Utils_eq(q.ck, item.ck) ? newQuoteItem : q;
						},
						model.h);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: newItems, m: $author$project$Page$NewQuote$Dirty}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var item = msg.a;
				var param = msg.b;
				var m = msg.c;
				var rfId = $author$project$RatingFactor$ratingFactorIdToString(param.i.ck);
				var newParam = _Utils_update(
					param,
					{
						_: $elm$core$Maybe$Just(m)
					});
				var newParams = A2(
					$elm$core$List$map,
					function (p) {
						return _Utils_eq(
							$author$project$RatingFactor$ratingFactorIdToString(p.i.ck),
							rfId) ? newParam : p;
					},
					item.u);
				var newQuoteItem = _Utils_update(
					item,
					{u: newParams});
				var newItems = A2(
					$elm$core$List$map,
					function (q) {
						return _Utils_eq(q.ck, item.ck) ? newQuoteItem : q;
					},
					model.h);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newItems, m: $author$project$Page$NewQuote$Dirty}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var item = msg.a;
				var param = msg.b;
				var y = msg.c;
				var rfId = $author$project$RatingFactor$ratingFactorIdToString(param.i.ck);
				var newParam = _Utils_update(
					param,
					{
						ag: $elm$core$Maybe$Just(y)
					});
				var newParams = A2(
					$elm$core$List$map,
					function (p) {
						return _Utils_eq(
							$author$project$RatingFactor$ratingFactorIdToString(p.i.ck),
							rfId) ? newParam : p;
					},
					item.u);
				var newQuoteItem = _Utils_update(
					item,
					{u: newParams});
				var newItems = A2(
					$elm$core$List$map,
					function (q) {
						return _Utils_eq(q.ck, item.ck) ? newQuoteItem : q;
					},
					model.h);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newItems, m: $author$project$Page$NewQuote$Dirty}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bG: $elm$core$Maybe$Just(state),
							m: $author$project$Page$NewQuote$Dirty
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var _v7 = _Utils_Tuple2(model.h, model.bG);
				if (_v7.b.$ === 1) {
					var _v8 = _v7.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					if (!_v7.a.b) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var quoteItems = _v7.a;
						var state = _v7.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{m: $author$project$Page$NewQuote$Saving}),
							A2($author$project$Page$NewQuote$createQuote, quoteItems, state));
					}
				}
			default:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v10 = httpResponse.a;
					var quote = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{m: $author$project$Page$NewQuote$Success}),
						A2(
							$author$project$Route$pushUrl,
							$author$project$Route$ShowQuote(quote.ck),
							model.y));
				} else {
					var err = httpResponse.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: $author$project$Page$NewQuote$Error(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$PayQuote$Complete = {$: 3};
var $author$project$Page$PayQuote$Error = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PayQuote$PaymentError = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$PayQuote$Ready = {$: 1};
var $author$project$Page$PayQuote$Submitting = {$: 2};
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$Page$PayQuote$confirmCardSetup = _Platform_outgoingPort('confirmCardSetup', $elm$json$Json$Encode$string);
var $author$project$Page$PayQuote$StripeEvent = F3(
	function (error, empty, complete) {
		return {aj: complete, aZ: empty, av: error};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Page$PayQuote$StripeError = function (message) {
	return {bf: message};
};
var $author$project$Page$PayQuote$decodeStripeError = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Page$PayQuote$StripeError));
var $author$project$Page$PayQuote$decodeStripeEvent = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'complete',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'empty',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'error',
			$elm$json$Json$Decode$nullable($author$project$Page$PayQuote$decodeStripeError),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Page$PayQuote$StripeEvent))));
var $author$project$Page$PayQuote$GotPolicy = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PayQuote$fetchPolicy = function (id) {
	return $elm$http$Http$get(
		{
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$PayQuote$GotPolicy, $author$project$Policy$decodePolicy),
			bM: '/api/v1/policies/' + ($author$project$Policy$policyIdToString(id) + '.json')
		});
};
var $author$project$Page$PayQuote$PaymentPrepared = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PayQuote$PreparedPayment = F3(
	function (quote, clientSecret, stripePublicKey) {
		return {aT: clientSecret, f: quote, bH: stripePublicKey};
	});
var $author$project$Page$PayQuote$decodePreparedPayment = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'stripePublicKey',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'clientSecret',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'quote',
			$author$project$Quote$decodeQuote,
			$elm$json$Json$Decode$succeed($author$project$Page$PayQuote$PreparedPayment))));
var $author$project$Page$PayQuote$preparePayment = function (quote) {
	return $elm$http$Http$request(
		{
			b2: $elm$http$Http$emptyBody,
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$PayQuote$PaymentPrepared, $author$project$Page$PayQuote$decodePreparedPayment),
			ci: _List_Nil,
			cs: 'POST',
			cS: $elm$core$Maybe$Nothing,
			cV: $elm$core$Maybe$Nothing,
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(quote.ck) + '/payment.json')
		});
};
var $author$project$Page$PayQuote$renderStripeForm = _Platform_outgoingPort(
	'renderStripeForm',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Page$PayQuote$FetchPolicy = function (a) {
	return {$: 11, a: a};
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Page$PayQuote$waitAndFetchPolicy = function (id) {
	return A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Page$PayQuote$FetchPolicy(id);
		},
		$elm$core$Process$sleep(2000));
};
var $author$project$Page$PayQuote$FetchQuote = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$PayQuote$waitAndFetchQuote = function (id) {
	return A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Page$PayQuote$FetchQuote(id);
		},
		$elm$core$Process$sleep(2000));
};
var $author$project$Page$PayQuote$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 10:
				var id = msg.a;
				var pollCount = model.J + 1;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: pollCount}),
					$author$project$Page$PayQuote$fetchQuote(id));
			case 11:
				var id = msg.a;
				var pollCount = model.J + 1;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: pollCount}),
					$author$project$Page$PayQuote$fetchPolicy(id));
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var quote = _v2.b;
					var _v3 = quote.cD;
					if (!_v3.$) {
						var id = _v3.a;
						return _Utils_Tuple2(
							model,
							$author$project$Page$PayQuote$fetchPolicy(id));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									O: quote.e.cu,
									f: $elm$core$Maybe$Just(quote)
								}),
							$author$project$Page$PayQuote$preparePayment(quote));
					}
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 1:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v5 = httpResponse.a;
					var policy = _v5.b;
					var _v6 = policy.m;
					if (!_v6) {
						var _v7 = model.as;
						if (!_v7.$) {
							var today = _v7.a;
							var d = A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, policy.cC.cL);
							if (A2($justinmimbs$date$Date$compare, today, d) === 1) {
								var _v8 = $elm$core$List$head(policy.cB);
								if (!_v8.$) {
									var payment = _v8.a;
									return (payment.m === 1) ? _Utils_Tuple2(
										model,
										A2(
											$author$project$Route$pushUrl,
											$author$project$Route$PolicyBilling(policy.ck),
											model.y)) : _Utils_Tuple2(
										model,
										A2(
											$author$project$Route$pushUrl,
											$author$project$Route$ShowPolicy(policy.ck),
											model.y));
								} else {
									return (model.J > 5) ? _Utils_Tuple2(
										model,
										A2(
											$author$project$Route$pushUrl,
											$author$project$Route$ShowPolicy(policy.ck),
											model.y)) : _Utils_Tuple2(
										model,
										$author$project$Page$PayQuote$waitAndFetchPolicy(policy.ck));
								}
							} else {
								return _Utils_Tuple2(
									model,
									A2(
										$author$project$Route$pushUrl,
										$author$project$Route$ShowPolicy(policy.ck),
										model.y));
							}
						} else {
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$pushUrl,
									$author$project$Route$ShowPolicy(policy.ck),
									model.y));
						}
					} else {
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$Route$pushUrl,
								$author$project$Route$ShowPolicy(policy.ck),
								model.y));
					}
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 2:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							as: $elm$core$Maybe$Just(date)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v10 = httpResponse.a;
					var prepared = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aH: $elm$core$Maybe$Just(prepared),
								f: $elm$core$Maybe$Just(prepared.f)
							}),
						$author$project$Page$PayQuote$renderStripeForm(
							_Utils_Tuple2(prepared.bH, prepared.aT)));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: $author$project$Page$PayQuote$Error('Payment could not be prepared')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{s: $author$project$Page$PayQuote$Ready}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var value = msg.a;
				var _v11 = A2($elm$json$Json$Decode$decodeValue, $author$project$Page$PayQuote$decodeStripeEvent, value);
				if (!_v11.$) {
					var event = _v11.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: $author$project$Page$PayQuote$Ready,
								S: $elm$core$Maybe$Just(event)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{S: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var _v12 = model.f;
				if (!_v12.$) {
					var quote = _v12.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{s: $author$project$Page$PayQuote$Complete}),
						$author$project$Page$PayQuote$waitAndFetchQuote(quote.ck));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var err = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: $author$project$Page$PayQuote$PaymentError(err)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var name = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: name}),
					$elm$core$Platform$Cmd$none);
			default:
				var _v13 = model.S;
				if (!_v13.$) {
					var event = _v13.a;
					return event.aj ? _Utils_Tuple2(
						_Utils_update(
							model,
							{s: $author$project$Page$PayQuote$Submitting}),
						$author$project$Page$PayQuote$confirmCardSetup(model.O)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$PolicyBilling$Complete = {$: 3};
var $author$project$Page$PolicyBilling$PaymentError = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$PolicyBilling$Submitting = {$: 2};
var $author$project$Page$PolicyBilling$Unneeded = {$: 0};
var $author$project$Page$PolicyBilling$Empty = {$: 0};
var $author$project$Page$PolicyBilling$Paid = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyBilling$Unpaid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PolicyBilling$billingStatus = function (payments) {
	var _v0 = $elm$core$List$head(payments);
	if (_v0.$ === 1) {
		return $author$project$Page$PolicyBilling$Empty;
	} else {
		var p = _v0.a;
		var _v1 = p.m;
		if (!_v1) {
			return $author$project$Page$PolicyBilling$Paid(p);
		} else {
			return $author$project$Page$PolicyBilling$Unpaid(p);
		}
	}
};
var $author$project$Page$PolicyBilling$confirmPaymentMethod = _Platform_outgoingPort('confirmPaymentMethod', $elm$json$Json$Encode$string);
var $author$project$Page$PolicyBilling$StripeEvent = F3(
	function (error, empty, complete) {
		return {aj: complete, aZ: empty, av: error};
	});
var $author$project$Page$PolicyBilling$StripeError = function (message) {
	return {bf: message};
};
var $author$project$Page$PolicyBilling$decodeStripeError = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Page$PolicyBilling$StripeError));
var $author$project$Page$PolicyBilling$decodeStripeEvent = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'complete',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'empty',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'error',
			$elm$json$Json$Decode$nullable($author$project$Page$PolicyBilling$decodeStripeError),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Page$PolicyBilling$StripeEvent))));
var $author$project$Page$PolicyBilling$renderStripePaymentMethodForm = _Platform_outgoingPort(
	'renderStripePaymentMethodForm',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Page$PolicyBilling$RenderStripeForm = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$PolicyBilling$waitAndRenderStripeForm = F2(
	function (pub, priv) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return A2($author$project$Page$PolicyBilling$RenderStripeForm, pub, priv);
			},
			$elm$core$Process$sleep(500));
	});
var $author$project$Page$PolicyBilling$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var policy = _v2.b;
					var _v3 = $author$project$Page$PolicyBilling$billingStatus(policy.cB);
					switch (_v3.$) {
						case 0:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										O: policy.e.cu,
										s: $author$project$Page$PolicyBilling$Unneeded,
										C: $elm$core$Maybe$Just(policy)
									}),
								$elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										O: policy.e.cu,
										s: $author$project$Page$PolicyBilling$Unneeded,
										C: $elm$core$Maybe$Just(policy)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							var p = _v3.a;
							var _v4 = p.aT;
							if (_v4.$ === 1) {
								var error = $author$project$Page$PolicyBilling$PaymentError('missing secret for failed payment');
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											O: policy.e.cu,
											s: error,
											C: $elm$core$Maybe$Just(policy)
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var k = _v4.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											O: policy.e.cu,
											C: $elm$core$Maybe$Just(policy)
										}),
									A2($author$project$Page$PolicyBilling$waitAndRenderStripeForm, policy.b3.cF, k));
							}
					}
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 1:
				var pub = msg.a;
				var priv = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{s: $author$project$Page$PolicyBilling$Ready}),
					$author$project$Page$PolicyBilling$renderStripePaymentMethodForm(
						_Utils_Tuple2(pub, priv)));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{s: $author$project$Page$PolicyBilling$Ready}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var value = msg.a;
				var _v5 = A2($elm$json$Json$Decode$decodeValue, $author$project$Page$PolicyBilling$decodeStripeEvent, value);
				if (!_v5.$) {
					var event = _v5.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: $author$project$Page$PolicyBilling$Ready,
								S: $elm$core$Maybe$Just(event)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{S: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var _v6 = model.C;
				if (!_v6.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{s: $author$project$Page$PolicyBilling$Complete}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var err = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: $author$project$Page$PolicyBilling$PaymentError(err)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var name = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: name}),
					$elm$core$Platform$Cmd$none);
			default:
				var _v7 = model.S;
				if (!_v7.$) {
					var event = _v7.a;
					return event.aj ? _Utils_Tuple2(
						_Utils_update(
							model,
							{s: $author$project$Page$PolicyBilling$Submitting}),
						$author$project$Page$PolicyBilling$confirmPaymentMethod(model.O)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$ShowPolicy$CertificateError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ShowPolicy$CertificateLoading = {$: 0};
var $author$project$Page$ShowPolicy$CertificateSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowPolicy$GotCertificate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowPolicy$Certificate = function (expiringUrl) {
	return {a3: expiringUrl};
};
var $author$project$Page$ShowPolicy$decodeCertificate = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'expiringUrl',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Page$ShowPolicy$Certificate));
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{b2: r.b2, a1: r.a1, ci: _List_Nil, cs: 'POST', cS: $elm$core$Maybe$Nothing, cV: $elm$core$Maybe$Nothing, bM: r.bM});
};
var $author$project$Page$ShowPolicy$fetchCertificate = function (id) {
	return $elm$http$Http$post(
		{
			b2: $elm$http$Http$emptyBody,
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$ShowPolicy$GotCertificate, $author$project$Page$ShowPolicy$decodeCertificate),
			bM: '/api/v1/policies/' + ($author$project$Policy$policyIdToString(id) + '/certificate.json')
		});
};
var $author$project$Page$ShowPolicy$newWindow = _Platform_outgoingPort('newWindow', $elm$json$Json$Encode$string);
var $author$project$Page$ShowPolicy$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var policy = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								C: $elm$core$Maybe$Just(policy)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 1:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v4 = httpResponse.a;
					var certificate = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Q: $elm$core$Maybe$Just(
									$author$project$Page$ShowPolicy$CertificateSuccess(certificate))
							}),
						$author$project$Page$ShowPolicy$newWindow(certificate.a3));
				} else {
					var err = httpResponse.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Q: $elm$core$Maybe$Just(
									$author$project$Page$ShowPolicy$CertificateError(err))
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var _v5 = model.C;
				if (!_v5.$) {
					var policy = _v5.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Q: $elm$core$Maybe$Just($author$project$Page$ShowPolicy$CertificateLoading)
							}),
						$author$project$Page$ShowPolicy$fetchCertificate(policy.ck));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$ShowQuote$Dirty = {$: 2};
var $author$project$Page$ShowQuote$Error = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ShowQuote$Initialised = {$: 1};
var $author$project$Page$ShowQuote$Saving = {$: 3};
var $author$project$Page$ShowQuote$Success = {$: 4};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Page$ShowQuote$readyToContinue = function (items) {
	if (!items.b) {
		return false;
	} else {
		return A2(
			$elm$core$List$all,
			$elm_community$maybe_extra$Maybe$Extra$isJust,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.cJ;
				},
				items));
	}
};
var $author$project$Page$ShowQuote$UpdatedQuote = function (a) {
	return {$: 3, a: a};
};
var $author$project$Bill$billingCycleUnitToString = function (u) {
	if (!u) {
		return 'monthly';
	} else {
		return 'annual';
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Page$ShowQuote$encodeQuote = function (quote) {
	var u = $author$project$Bill$billingCycleUnitToString(
		A2($elm$core$Maybe$withDefault, 1, quote.b0));
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quoteItems',
				A2($elm$json$Json$Encode$list, $author$project$QuoteItem$encodeQuoteItem, quote.h)),
				_Utils_Tuple2(
				'billingCycle',
				$elm$json$Json$Encode$string(u))
			]));
};
var $author$project$Page$ShowQuote$updateQuote = function (quote) {
	return $elm$http$Http$request(
		{
			b2: $elm$http$Http$jsonBody(
				$author$project$Page$ShowQuote$encodeQuote(quote)),
			a1: A2($author$project$ErrorDetailed$expectJsonDetailed, $author$project$Page$ShowQuote$UpdatedQuote, $author$project$Quote$decodeQuote),
			ci: _List_Nil,
			cs: 'PATCH',
			cS: $elm$core$Maybe$Nothing,
			cV: $elm$core$Maybe$Nothing,
			bM: '/api/v1/quotes/' + ($author$project$Quote$quoteIdToString(quote.ck) + '.json')
		});
};
var $author$project$Page$ShowQuote$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v2 = httpResponse.a;
					var quote = _v2.b;
					var _v3 = quote.cD;
					if (!_v3.$) {
						var id = _v3.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$Route$pushUrl,
								$author$project$Route$ShowPolicy(id),
								model.y));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									f: $elm$core$Maybe$Just(quote),
									m: $author$project$Page$ShowQuote$Initialised
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, $author$project$Route$NotFound, model.y));
				}
			case 1:
				var item = msg.a;
				var product = msg.b;
				var _v4 = model.f;
				if (_v4.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v4.a;
					var newItem = _Utils_update(
						item,
						{
							cJ: $elm$core$Maybe$Just(product)
						});
					var newQuoteItems = A2(
						$elm$core$List$map,
						function (i) {
							return _Utils_eq(i.ck, item.ck) ? newItem : i;
						},
						quote.h);
					var newQuote = _Utils_update(
						quote,
						{h: newQuoteItems});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote),
								m: $author$project$Page$ShowQuote$Dirty
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var _v5 = model.f;
				if (_v5.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var quote = _v5.a;
					return $author$project$Page$ShowQuote$readyToContinue(quote.h) ? _Utils_Tuple2(
						_Utils_update(
							model,
							{m: $author$project$Page$ShowQuote$Saving}),
						$author$project$Page$ShowQuote$updateQuote(quote)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var httpResponse = msg.a;
				if (!httpResponse.$) {
					var _v7 = httpResponse.a;
					var quote = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{m: $author$project$Page$ShowQuote$Success}),
						A2(
							$author$project$Route$pushUrl,
							$author$project$Route$EditQuote(quote.ck),
							model.y));
				} else {
					var error = httpResponse.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: $author$project$Page$ShowQuote$Error(error)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var n = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{V: n}),
					$elm$core$Platform$Cmd$none);
			default:
				var u = msg.a;
				var _v8 = model.f;
				if (_v8.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var q = _v8.a;
					var newQuote = _Utils_update(
						q,
						{
							b0: $elm$core$Maybe$Just(u)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: $elm$core$Maybe$Just(newQuote)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.r);
		_v0$8:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.y,
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 1:
					var url = _v0.a.a;
					var newRoute = $author$project$Route$parseUrl(url);
					return $author$project$Main$initCurrentPage(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{an: newRoute}),
							$elm$core$Platform$Cmd$none));
				case 2:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v2 = A2($author$project$Page$NewQuote$update, subMsg, pageModel);
						var updatedPageModel = _v2.a;
						var updatedCmd = _v2.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$NewQuotePage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$NewQuotePageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
				case 3:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v3 = A2($author$project$Page$ShowQuote$update, subMsg, pageModel);
						var updatedPageModel = _v3.a;
						var updatedCmd = _v3.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$ShowQuotePage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$ShowQuotePageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
				case 4:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v4 = A2($author$project$Page$EditQuote$update, subMsg, pageModel);
						var updatedPageModel = _v4.a;
						var updatedCmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$EditQuotePage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$EditQuotePageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
				case 5:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v5 = A2($author$project$Page$PayQuote$update, subMsg, pageModel);
						var updatedPageModel = _v5.a;
						var updatedCmd = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$PayQuotePage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$PayQuotePageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
				case 6:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v6 = A2($author$project$Page$ShowPolicy$update, subMsg, pageModel);
						var updatedPageModel = _v6.a;
						var updatedCmd = _v6.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$ShowPolicyPage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$ShowPolicyPageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
				default:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var pageModel = _v0.b.a;
						var _v7 = A2($author$project$Page$PolicyBilling$update, subMsg, pageModel);
						var updatedPageModel = _v7.a;
						var updatedCmd = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: $author$project$Main$PolicyBillingPage(updatedPageModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$PolicyBillingPageMsg, updatedCmd));
					} else {
						break _v0$8;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $feathericons$elm_feather$FeatherIcons$Icon = $elm$core$Basics$identity;
var $feathericons$elm_feather$FeatherIcons$defaultAttributes = function (name) {
	return {
		ai: $elm$core$Maybe$Just('feather feather-' + name),
		bE: 24,
		aa: '',
		ap: 2,
		at: '0 0 24 24'
	};
};
var $feathericons$elm_feather$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			n: $feathericons$elm_feather$FeatherIcons$defaultAttributes(name),
			a: src
		};
	});
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $feathericons$elm_feather$FeatherIcons$facebook = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'facebook',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z')
				]),
			_List_Nil)
		]));
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $feathericons$elm_feather$FeatherIcons$instagram = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'instagram',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('2'),
					$elm$svg$Svg$Attributes$y('2'),
					$elm$svg$Svg$Attributes$width('20'),
					$elm$svg$Svg$Attributes$height('20'),
					$elm$svg$Svg$Attributes$rx('5'),
					$elm$svg$Svg$Attributes$ry('5')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('17.5'),
					$elm$svg$Svg$Attributes$y1('6.5'),
					$elm$svg$Svg$Attributes$x2('17.51'),
					$elm$svg$Svg$Attributes$y2('6.5')
				]),
			_List_Nil)
		]));
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$small = _VirtualDom_node('small');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $feathericons$elm_feather$FeatherIcons$toHtml = F2(
	function (attributes, _v0) {
		var src = _v0.a;
		var attrs = _v0.n;
		var strSize = $elm$core$String$fromFloat(attrs.bE);
		var baseAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Attributes$height(
				_Utils_ap(strSize, attrs.aa)),
				$elm$svg$Svg$Attributes$width(
				_Utils_ap(strSize, attrs.aa)),
				$elm$svg$Svg$Attributes$stroke('currentColor'),
				$elm$svg$Svg$Attributes$strokeLinecap('round'),
				$elm$svg$Svg$Attributes$strokeLinejoin('round'),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(attrs.ap)),
				$elm$svg$Svg$Attributes$viewBox(attrs.at)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _v1 = attrs.ai;
				if (!_v1.$) {
					var c = _v1.a;
					return A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			$elm$svg$Svg$svg,
			combinedAttributes,
			A2(
				$elm$core$List$map,
				$elm$svg$Svg$map($elm$core$Basics$never),
				src));
	});
var $feathericons$elm_feather$FeatherIcons$twitter = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'twitter',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z')
				]),
			_List_Nil)
		]));
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $feathericons$elm_feather$FeatherIcons$withClass = F2(
	function (_class, _v0) {
		var attrs = _v0.n;
		var src = _v0.a;
		return {
			n: _Utils_update(
				attrs,
				{
					ai: $elm$core$Maybe$Just(_class)
				}),
			a: src
		};
	});
var $author$project$Main$pageFooter = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'footer-heading'),
			$elm$html$Html$Attributes$class('bg-white border-t border-gray-200')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sr-only'),
					$elm$html$Html$Attributes$id('footer-heading')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Footer')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 md:gap-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-y-3 sm:col-span-2 md:col-span-1 ')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$alt('Pets By Jane'),
											$elm$html$Html$Attributes$class('h-10'),
											$elm$html$Html$Attributes$src('/images/logo-pets-jane.png')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-gray-500 text-sm mb-2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('They love us unconditionally, so when our pets are injured or sick, it really pulls on the heartstrings. So we do everything we can do help them.')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex space-x-6 mb-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-400 hover:text-gray-500'),
													$elm$html$Html$Attributes$href('#')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sr-only')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Facebook')
														])),
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current', $feathericons$elm_feather$FeatherIcons$facebook))
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-400 hover:text-gray-500'),
													$elm$html$Html$Attributes$href('#')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sr-only')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Instagram')
														])),
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current', $feathericons$elm_feather$FeatherIcons$instagram))
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-400 hover:text-gray-500'),
													$elm$html$Html$Attributes$href('#')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sr-only')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Twitter')
														])),
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current', $feathericons$elm_feather$FeatherIcons$twitter))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-y-8 sm:col-span-1 mt-4 md:mt-0')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-400 tracking-wider uppercase')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Notices')
										])),
									A2(
									$elm$html$Html$ul,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-4 text-sm'),
											A2($elm$html$Html$Attributes$attribute, 'role', 'list')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Insurance Brokers Code of Practice')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Financial Services Guide ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Privacy ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Terms ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Complaints ')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-y-8 sm:col-span-1 mt-4 md:mt-0')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-400 tracking-wider uppercase')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Policies')
										])),
									A2(
									$elm$html$Html$ul,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-4 text-sm'),
											A2($elm$html$Html$Attributes$attribute, 'role', 'list')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Financial Hardship ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Vulnerability ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Family & Domestic Violence Policy ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Translation and Interpretation ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Target Market Determinations ')
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-gray-500 hover:text-gray-900'),
															$elm$html$Html$Attributes$href('#')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Design and Distribution ')
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-12 border-t border-gray-200 pt-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-base text-gray-400 xl:text-justify')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$small,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('The above contains general advice only and has been prepared without considering your objectives, financial situation or needs. Cover is subject to the eligibility criteria and terms & conditions contained in the '),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href('#')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Combined Product Disclosure Statement and Financial Services Guide')
												])),
											$elm$html$Html$text('and our '),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href('#')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Target Market Determination')
												])),
											$elm$html$Html$text(', which you should read to decide whether this insurance product suits your needs. Insurance is issued by '),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href('https://www.agileunderwriting.com')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Agile Underwriting Services Pty Limited')
												])),
											$elm$html$Html$text('(ABN 48 607 908 243, AFSL 483374) Coverholder at Lloyd\'s and distributed through its agent '),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href('https://insuranceservice.holdings')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Insurance Service Holdings Pty Ltd')
												])),
											$elm$html$Html$text('(ABN 36 612 629 295, AFSL 491165). \"Pets with Jane\" is a trading name of '),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href('https://www.insuredbyus.com')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Insured by Us Pty Limited')
												])),
											$elm$html$Html$text('(ABN 84 604 520 952 AR 1008379) who is a corporate authorised representatives of Insurance Service Holdings and in arranging this insurance act on behalf of Insurance Service Holdings and Agile Underwriting Services and not on your behalf. Insurance Service Holdings and Insured by Us receive commission for each insurance policy sold.')
										]))
								]))
						]))
				]))
		]));
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Main$pageHeader = A2(
	$elm$html$Html$header,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('bg-white shadow')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Top'),
					$elm$html$Html$Attributes$class('max-w-7xl mx-auto px-4 sm:px-6 lg:px-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full py-6 flex items-center justify-between')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('#')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-xl font-bold text-orange-600')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Pets By Jane')
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ml-10 text-sm')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-gray-700')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Existing customer?')
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-orange-600 ml-1 hover:underline focus:underline'),
											$elm$html$Html$Attributes$href('#')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Sign in')
										]))
								]))
						]))
				]))
		]));
var $author$project$Main$pageTitle = function (page) {
	switch (page.$) {
		case 0:
			return 'Page not found';
		case 1:
			return 'Get a pet insurance quote';
		case 2:
			return 'Your pet insurance quote';
		case 3:
			return 'Your details';
		case 4:
			return 'Pet insurance payment';
		case 5:
			return 'Your policy';
		default:
			return 'Policy billing';
	}
};
var $author$project$PurchasePathStep$EditQuoteStep = function (a) {
	return {$: 2, a: a};
};
var $author$project$PurchasePathStep$NewQuoteStep = function (a) {
	return {$: 0, a: a};
};
var $author$project$PurchasePathStep$PayQuoteStep = function (a) {
	return {$: 3, a: a};
};
var $author$project$PurchasePathStep$ShowQuoteStep = function (a) {
	return {$: 1, a: a};
};
var $author$project$PurchasePathStep$StepComplete = 2;
var $author$project$PurchasePathStep$StepCurrent = 1;
var $author$project$PurchasePathStep$StepUpcoming = 0;
var $author$project$Page$EditQuote$CustomerMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$EditQuote$SetAddress1 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$EditQuote$SetCity = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$EditQuote$SetEmail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$EditQuote$SetName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$EditQuote$SetPhoneNumber = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$EditQuote$SetPostcode = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$EditQuote$SetState = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$EditQuote$SubmitForm = {$: 5};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $feathericons$elm_feather$FeatherIcons$alertTriangle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'alert-triangle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('9'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('13')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('17'),
					$elm$svg$Svg$Attributes$x2('12.01'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil)
		]));
var $author$project$ErrorResponse$ErrorResponse = function (errors) {
	return {a$: errors};
};
var $author$project$ErrorResponse$decodeErrorResponse = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'errors',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($author$project$ErrorResponse$ErrorResponse));
var $author$project$ErrorResponse$tryDecodeErrorResponse = function (body) {
	var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$ErrorResponse$decodeErrorResponse, body);
	if (!_v0.$) {
		var errorResponse = _v0.a;
		return errorResponse;
	} else {
		return $author$project$ErrorResponse$ErrorResponse(
			_List_fromArray(
				['Error parsing error response from server']));
	}
};
var $author$project$ErrorResponse$errorNotification = function (error) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('rounded-md bg-red-50 p-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-shrink-0')
							]),
						_List_fromArray(
							[
								A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-5 h-auto stroke-current text-red-400', $feathericons$elm_feather$FeatherIcons$alertTriangle))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-3')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-sm font-medium text-red-800')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Error')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mt-2 text-sm text-red-700')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$ul,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('list-disc pl-5 space-y-1')
											]),
										function () {
											if (error.$ === 3) {
												var body = error.b;
												var err = $author$project$ErrorResponse$tryDecodeErrorResponse(body);
												return A2(
													$elm$core$List$map,
													function (e) {
														return A2(
															$elm$html$Html$li,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(e)
																]));
													},
													err.a$);
											} else {
												return _List_fromArray(
													[
														A2(
														$elm$html$Html$li,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Server error encountered')
															]))
													]);
											}
										}())
									]))
							]))
					]))
			]));
};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.ag - 1)) + ($justinmimbs$date$Date$monthToNumber(date._) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.aX,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Page$EditQuote$addWeekFrom = F2(
	function (d, n) {
		return A3($justinmimbs$date$Date$add, 2, n, d);
	});
var $author$project$Page$EditQuote$SetStartsAt = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Page$EditQuote$maybeTimeMatchesDate = F2(
	function (m, d) {
		if (!m.$) {
			var t = m.a;
			return 1 === A2(
				$justinmimbs$date$Date$compare,
				d,
				A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, t));
		} else {
			return false;
		}
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$EditQuote$dayOption = F2(
	function (chosenTime, d) {
		var isChecked = A2($author$project$Page$EditQuote$maybeTimeMatchesDate, chosenTime, d);
		var inputId = 'startsAtDay' + $justinmimbs$date$Date$toIsoString(d);
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-md cursor-pointer relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 bg-white text-xs font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500', true),
							_Utils_Tuple2('hover:bg-gray-50', !isChecked),
							_Utils_Tuple2('bg-gray-200', isChecked)
						])),
					$elm$html$Html$Attributes$for(inputId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($justinmimbs$date$Date$format, 'E d MMM', d)),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only'),
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$name('startsAtDay'),
							$elm$html$Html$Attributes$required(true),
							$elm$html$Html$Events$onInput($author$project$Page$EditQuote$SetStartsAt),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$toRataDie(d))),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil)
				]));
	});
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Week = 3;
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $author$project$Page$EditQuote$daysOfWeek = function (d) {
	var start = A2($justinmimbs$date$Date$floor, 3, d);
	var finish = A3($justinmimbs$date$Date$add, 3, 7, d);
	return A4($justinmimbs$date$Date$range, 11, 1, start, finish);
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Page$EditQuote$SetStartsAtWeek = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$EditQuote$maybeDatesMatch = F2(
	function (d1, m) {
		if (!m.$) {
			var d2 = m.a;
			return A2($justinmimbs$date$Date$compare, d1, d2) === 1;
		} else {
			return false;
		}
	});
var $author$project$Page$EditQuote$weekOption = F2(
	function (chosen, _v0) {
		var d = _v0.a;
		var copy = _v0.b;
		var isChecked = A2($author$project$Page$EditQuote$maybeDatesMatch, d, chosen);
		var inputId = 'startsAtWeek' + $justinmimbs$date$Date$toIsoString(d);
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-md cursor-pointer relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 bg-white text-xs font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500', true),
							_Utils_Tuple2('hover:bg-gray-50', !isChecked),
							_Utils_Tuple2('bg-gray-200', isChecked)
						])),
					$elm$html$Html$Attributes$for(inputId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(copy),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only'),
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$name('startsAtWeek'),
							$elm$html$Html$Attributes$required(true),
							$elm$html$Html$Events$onInput($author$project$Page$EditQuote$SetStartsAtWeek),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$toRataDie(d))),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$EditQuote$startsAtChoices = F3(
	function (today, chosenWeek, at) {
		var weeks = A2(
			$elm$core$List$map,
			$elm$core$Tuple$mapFirst(
				$author$project$Page$EditQuote$addWeekFrom(today)),
			_List_fromArray(
				[
					_Utils_Tuple2(0, 'This week'),
					_Utils_Tuple2(1, 'Next week'),
					_Utils_Tuple2(2, 'In 2 weeks'),
					_Utils_Tuple2(3, 'In 3 weeks')
				]));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb-4 flex flex-wrap gap-2')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$EditQuote$weekOption(chosenWeek),
						weeks)),
					function () {
					if (chosenWeek.$ === 1) {
						return $elm$html$Html$text('');
					} else {
						var w = chosenWeek.a;
						var days = A2(
							$elm$core$List$filter,
							function (d) {
								return !(!A2($justinmimbs$date$Date$compare, d, today));
							},
							$author$project$Page$EditQuote$daysOfWeek(w));
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-wrap gap-2')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Page$EditQuote$dayOption(at),
								days));
					}
				}()
				]));
	});
var $author$project$Page$EditQuote$stateChanged = F2(
	function (orig, sel) {
		return !_Utils_eq(
			sel,
			A2($elm$core$Maybe$withDefault, '', orig));
	});
var $author$project$Page$EditQuote$checkoutForm = F5(
	function (quote, status, today, startsAtWeek, origState) {
		var states = _List_fromArray(
			['NSW', 'ACT', 'VIC', 'QLD', 'NT', 'TAS', 'WA', 'SA']);
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit($author$project$Page$EditQuote$SubmitForm)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('shadow sm:rounded-md sm:overflow-hidden')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6 border-b border-gray-200')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm leading-6 text-gray-600 mb-0')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Step 3')
										])),
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Tell us about you')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6')
								]),
							_List_fromArray(
								[
									function () {
									if (status.$ === 5) {
										var error = status.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mb-3')
												]),
											_List_fromArray(
												[
													$author$project$ErrorResponse$errorNotification(error)
												]));
									} else {
										return $elm$html$Html$text('');
									}
								}(),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('first-name')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Name')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'name'),
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('name'),
																			$elm$html$Html$Attributes$name('name'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(quote.e.cu),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetName)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('company')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Email address')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'email'),
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('email'),
																			$elm$html$Html$Attributes$name('email'),
																			$elm$html$Html$Attributes$type_('email'),
																			$elm$html$Html$Attributes$value(quote.e.aY),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetEmail)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('address1')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Street address')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'street-address'),
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('address1'),
																			$elm$html$Html$Attributes$name('address1'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(quote.e.aP),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetAddress1)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('city')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('City')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('city'),
																			$elm$html$Html$Attributes$name('city'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(quote.e.aS),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetCity)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('country')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Country')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-50 text-gray-600'),
																			$elm$html$Html$Attributes$id('country'),
																			$elm$html$Html$Attributes$name('country'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(quote.e.aW),
																			$elm$html$Html$Attributes$required(true),
																			$elm$html$Html$Attributes$disabled(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('state')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('State')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1 flex items-start')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$select,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'state'),
																			$elm$html$Html$Attributes$class('block border-gray-300 rounded-md shadow-sm flex-0  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('state'),
																			$elm$html$Html$Attributes$name('state'),
																			$elm$html$Html$Attributes$required(true),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetState))
																		]),
																	A2(
																		$elm$core$List$map,
																		function (s) {
																			return A2(
																				$elm$html$Html$option,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$value(s),
																						$elm$html$Html$Attributes$selected(
																						_Utils_eq(quote.e.bG, s))
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text(s)
																					]));
																		},
																		states)),
																	A2($author$project$Page$EditQuote$stateChanged, origState, quote.e.bG) ? A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('flex-1 rounded-md bg-yellow-50 p-4 ml-3 text-sm text-gray-600')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Your state has changed which may result in a small adjustment to your stamp duty and total price')
																		])) : $elm$html$Html$text('')
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('postcode')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Postcode')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'postal-code'),
																			$elm$html$Html$Attributes$class('block w-40 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('postcode'),
																			$elm$html$Html$Attributes$name('postcode'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(quote.e.bq),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetPostcode)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('phoneNumber')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Phone')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-1')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'tel'),
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('phoneNumber'),
																			$elm$html$Html$Attributes$name('phoneNumber'),
																			$elm$html$Html$Attributes$type_('tel'),
																			$elm$html$Html$Attributes$value(quote.e.bk),
																			$elm$html$Html$Events$onInput(
																			A2($elm$core$Basics$composeL, $author$project$Page$EditQuote$CustomerMsg, $author$project$Page$EditQuote$SetPhoneNumber)),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('sm:col-span-2 border-t border-gray-200 pt-6')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h2,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-sm leading-6 text-gray-600 mb-0')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Policy timing')
																])),
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700 mb-6')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Select a start date for your policy')
																])),
															function () {
															if (!today.$) {
																var d = today.a;
																return A3($author$project$Page$EditQuote$startsAtChoices, d, startsAtWeek, quote.ao);
															} else {
																return $elm$html$Html$text('');
															}
														}()
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-6 px-4 bg-gray-50 sm:px-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-xs mb-6 font-light text-slate-500')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(' By continuing you consent to all insurance documentation in relation to your policy being sent to your chosen email address.')
										])),
									function () {
									switch (status.$) {
										case 0:
											return $elm$html$Html$text('');
										case 3:
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('bg-green-700 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Submitting…')
													]));
										case 5:
											return $elm$html$Html$text('');
										case 4:
											return $elm$html$Html$text('');
										default:
											return A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('bg-green-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-500'),
														$elm$html$Html$Attributes$type_('submit')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Continue to payment')
													]));
									}
								}()
								]))
						]))
				]));
	});
var $feathericons$elm_feather$FeatherIcons$loader = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'loader',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('2'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('18'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('22')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('4.93'),
					$elm$svg$Svg$Attributes$y1('4.93'),
					$elm$svg$Svg$Attributes$x2('7.76'),
					$elm$svg$Svg$Attributes$y2('7.76')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16.24'),
					$elm$svg$Svg$Attributes$y1('16.24'),
					$elm$svg$Svg$Attributes$x2('19.07'),
					$elm$svg$Svg$Attributes$y2('19.07')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('2'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('18'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('22'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('4.93'),
					$elm$svg$Svg$Attributes$y1('19.07'),
					$elm$svg$Svg$Attributes$x2('7.76'),
					$elm$svg$Svg$Attributes$y2('16.24')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16.24'),
					$elm$svg$Svg$Attributes$y1('7.76'),
					$elm$svg$Svg$Attributes$x2('19.07'),
					$elm$svg$Svg$Attributes$y2('4.93')
				]),
			_List_Nil)
		]));
var $author$project$PurchasePathStep$navItem = F4(
	function (status, i, title, route) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('sm:flex-1', true),
							_Utils_Tuple2('text-green-600', status === 2),
							_Utils_Tuple2('text-orange-600', status === 1),
							_Utils_Tuple2('text-gray-600', !status)
						]))
				]),
			_List_fromArray(
				[
					function () {
					var _v0 = _Utils_Tuple2(status, route);
					if (!_v0.a) {
						var _v1 = _v0.a;
						return A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('border-gray-600 pl-4 py-2 flex flex-col border-l-4 border-gray-600 sm:pl-0 sm:pt-4 sm:pb-0 sm:border-l-0 sm:border-t-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-xs font-semibold tracking-wide uppercase')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Step ' + $elm$core$String$fromInt(i + 1))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-medium')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(title)
										]))
								]));
					} else {
						if (!_v0.b.$) {
							var r = _v0.b.a;
							return A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('pl-4 py-2 flex flex-col border-l-4 sm:pl-0 sm:pt-4 sm:pb-0 sm:border-l-0 sm:border-t-4', true),
												_Utils_Tuple2('border-green-600', status === 2),
												_Utils_Tuple2('border-orange-600', status === 1)
											])),
										$elm$html$Html$Attributes$href(
										$author$project$Route$routeToString(r))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-xs font-semibold tracking-wide uppercase')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Step ' + $elm$core$String$fromInt(i + 1))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-sm font-medium')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(title)
											]))
									]));
						} else {
							return $elm$html$Html$text('');
						}
					}
				}()
				]));
	});
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$PurchasePathStep$purchasePathStepsNav = F2(
	function (quote, allSteps) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$nav,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Progress')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ol,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-y-4 sm:flex sm:space-y-0 sm:space-x-8'),
									A2($elm$html$Html$Attributes$attribute, 'role', 'list')
								]),
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, s) {
										switch (s.$) {
											case 0:
												var status = s.a;
												return A4(
													$author$project$PurchasePathStep$navItem,
													status,
													i,
													'About your pet',
													$elm$core$Maybe$Just($author$project$Route$NewQuote));
											case 1:
												var status = s.a;
												if (!quote.$) {
													var q = quote.a;
													return A4(
														$author$project$PurchasePathStep$navItem,
														status,
														i,
														'Select your plan',
														$elm$core$Maybe$Just(
															$author$project$Route$ShowQuote(q.ck)));
												} else {
													return A4($author$project$PurchasePathStep$navItem, status, i, 'Select your plan', $elm$core$Maybe$Nothing);
												}
											case 2:
												var status = s.a;
												if (!quote.$) {
													var q = quote.a;
													return A4(
														$author$project$PurchasePathStep$navItem,
														status,
														i,
														'About you',
														$elm$core$Maybe$Just(
															$author$project$Route$EditQuote(q.ck)));
												} else {
													return A4($author$project$PurchasePathStep$navItem, status, i, 'About you', $elm$core$Maybe$Nothing);
												}
											default:
												var status = s.a;
												if (!quote.$) {
													var q = quote.a;
													return A4(
														$author$project$PurchasePathStep$navItem,
														status,
														i,
														'Confirm & pay',
														$elm$core$Maybe$Just(
															$author$project$Route$PayQuote(q.ck)));
												} else {
													return A4($author$project$PurchasePathStep$navItem, status, i, 'Confirm & pay', $elm$core$Maybe$Nothing);
												}
										}
									}),
								allSteps))
						]))
				]));
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$EditQuote$describeBillingCycleOption = function (o) {
	var _v0 = o.cW;
	if (!_v0) {
		return _Utils_Tuple2(
			A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold block')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Billing monthly')
							])),
						$elm$html$Html$text('12 monthly payments')
					])),
			o.cA);
	} else {
		return _Utils_Tuple2(
			A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold block')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Billing annually')
							])),
						$elm$html$Html$text('1 annual payment')
					])),
			o.cA);
	}
};
var $author$project$Page$EditQuote$findBillingCycleOption = F2(
	function (options, c) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (o) {
				return _Utils_eq(o.cW, c);
			},
			options);
	});
var $author$project$Page$EditQuote$billingCycleBreakdown = F2(
	function (chosen, options) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$Page$EditQuote$describeBillingCycleOption,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Page$EditQuote$findBillingCycleOption(options),
				chosen));
	});
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {A: decimals, bb: integers, bh: original, am: prefix, aq: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.bb,
				$elm$core$List$singleton(formatted.A))));
	return onlyZeros ? 1 : ((formatted.bh < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.A;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.A;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian = function (integers) {
	var thousand = ($elm$core$String$length(integers) > 3) ? A2($elm$core$String$right, 3, integers) : integers;
	var reversedSplitHundreds = function (value) {
		return ($elm$core$String$length(value) > 2) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 2, value),
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 2, value))) : ((!$elm$core$String$length(value)) ? _List_Nil : _List_fromArray(
			[value]));
	};
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$cons,
			thousand,
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 3, integers))));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitIntegers = F2(
	function (system, integers) {
		if (!system) {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		} else {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		}
	});
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = A2(
			$cuducos$elm_format_number$FormatNumber$Parser$splitIntegers,
			locale.ar,
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{am: locale.aC, aq: locale.aD});
			case 0:
				return _Utils_update(
					partial,
					{am: locale.bo, aq: locale.bp});
			default:
				return _Utils_update(
					partial,
					{am: locale.bT, aq: locale.bU});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.G, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.E, formatted.bb);
		var decimals = stringfyDecimals(formatted.A);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.am, integers, decimals, formatted.aq]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $Chadtech$elm_money$Money$toNativeSymbol = function (currency) {
	switch (currency) {
		case 0:
			return '$';
		case 1:
			return '$';
		case 2:
			return '€';
		case 3:
			return '฿';
		case 4:
			return 'د.إ.\u200F';
		case 5:
			return '؋';
		case 6:
			return 'Lek';
		case 7:
			return 'դր.';
		case 8:
			return '$';
		case 9:
			return '$';
		case 10:
			return 'ман.';
		case 11:
			return 'KM';
		case 12:
			return '৳';
		case 13:
			return 'лв.';
		case 14:
			return 'د.ب.\u200F';
		case 15:
			return 'FBu';
		case 16:
			return '$';
		case 17:
			return 'Bs';
		case 18:
			return 'R$';
		case 19:
			return 'P';
		case 20:
			return 'BYN';
		case 21:
			return '$';
		case 22:
			return 'FrCD';
		case 23:
			return 'CHF';
		case 24:
			return '$';
		case 25:
			return 'undefined';
		case 26:
			return '$';
		case 27:
			return '₡';
		case 28:
			return 'CV$';
		case 29:
			return 'Kč';
		case 30:
			return 'Fdj';
		case 31:
			return 'kr';
		case 32:
			return 'RD$';
		case 33:
			return 'د.ج.\u200F';
		case 34:
			return 'kr';
		case 35:
			return 'ج.م.\u200F';
		case 36:
			return 'Nfk';
		case 37:
			return 'Br';
		case 38:
			return '£';
		case 39:
			return 'GEL';
		case 40:
			return 'GH₵';
		case 41:
			return 'FG';
		case 42:
			return 'Q';
		case 43:
			return '$';
		case 44:
			return 'L';
		case 45:
			return 'kn';
		case 46:
			return 'Ft';
		case 47:
			return 'Rp';
		case 48:
			return '₪';
		case 49:
			return '₹';
		case 50:
			return 'د.ع.\u200F';
		case 51:
			return '﷼';
		case 52:
			return 'kr';
		case 53:
			return '$';
		case 54:
			return 'د.أ.\u200F';
		case 55:
			return '￥';
		case 56:
			return 'Ksh';
		case 57:
			return '៛';
		case 58:
			return 'FC';
		case 59:
			return '₩';
		case 60:
			return 'د.ك.\u200F';
		case 61:
			return 'тңг.';
		case 62:
			return 'ກີບ';
		case 63:
			return 'ل.ل.\u200F';
		case 64:
			return 'SL Re';
		case 65:
			return 'Lt';
		case 66:
			return 'Ls';
		case 67:
			return 'د.ل.\u200F';
		case 68:
			return 'د.م.\u200F';
		case 69:
			return 'MDL';
		case 70:
			return 'MGA';
		case 71:
			return 'MKD';
		case 72:
			return 'K';
		case 73:
			return 'MOP$';
		case 74:
			return 'MURs';
		case 75:
			return '$';
		case 76:
			return 'RM';
		case 77:
			return 'MTn';
		case 78:
			return 'N$';
		case 79:
			return '₦';
		case 80:
			return 'C$';
		case 81:
			return 'kr';
		case 82:
			return 'नेरू';
		case 83:
			return '$';
		case 84:
			return 'ر.ع.\u200F';
		case 85:
			return 'B/.';
		case 86:
			return 'S/.';
		case 87:
			return '₱';
		case 88:
			return '₨';
		case 89:
			return 'zł';
		case 90:
			return '₲';
		case 91:
			return 'ر.ق.\u200F';
		case 92:
			return 'RON';
		case 93:
			return 'дин.';
		case 94:
			return '₽';
		case 95:
			return 'FR';
		case 96:
			return 'ر.س.\u200F';
		case 97:
			return 'SDG';
		case 98:
			return 'kr';
		case 99:
			return '$';
		case 100:
			return 'Ssh';
		case 101:
			return 'ل.س.\u200F';
		case 102:
			return '฿';
		case 103:
			return 'د.ت.\u200F';
		case 104:
			return 'T$';
		case 105:
			return 'TL';
		case 106:
			return '$';
		case 107:
			return 'NT$';
		case 108:
			return 'TSh';
		case 109:
			return '₴';
		case 110:
			return 'USh';
		case 111:
			return '$';
		case 112:
			return 'UZS';
		case 113:
			return 'Bs.F.';
		case 114:
			return '₫';
		case 115:
			return 'FCFA';
		case 116:
			return 'CFA';
		case 117:
			return 'ر.ي.\u200F';
		case 118:
			return 'R';
		case 119:
			return 'ZK';
		case 120:
			return 'Kz';
		case 121:
			return '$';
		case 122:
			return 'ƒ';
		case 123:
			return '$';
		case 124:
			return '$';
		case 125:
			return '$';
		case 126:
			return 'Nu';
		case 127:
			return '$';
		case 128:
			return '₱';
		case 129:
			return 'ƒ';
		case 130:
			return 'E';
		case 131:
			return '£';
		case 132:
			return '$';
		case 133:
			return 'F';
		case 134:
			return 'D';
		case 135:
			return '£';
		case 136:
			return '$';
		case 137:
			return 'G';
		case 138:
			return '₩';
		case 139:
			return 'С̲';
		case 140:
			return 'M';
		case 141:
			return '$';
		case 142:
			return 'K';
		case 143:
			return 'ރ';
		case 144:
			return 'UM';
		case 145:
			return '₮';
		case 146:
			return 'K';
		case 147:
			return '£';
		case 148:
			return '$';
		case 149:
			return 'Db';
		case 150:
			return 'SRe';
		case 151:
			return 'Le';
		case 152:
			return '$';
		case 153:
			return '£';
		case 154:
			return '$';
		case 155:
			return 'SM';
		case 156:
			return 'm';
		case 157:
			return 'VT';
		case 158:
			return 'Bs';
		case 159:
			return 'K';
		default:
			return '$';
	}
};
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Western = 0;
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	G: '.',
	A: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	aC: '−',
	aD: '',
	bo: '',
	bp: '',
	ar: 0,
	E: '',
	bT: '',
	bU: ''
};
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		A: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2),
		E: ','
	});
var $author$project$Bill$formatMoney = F2(
	function (currency, cents) {
		var symbol = $Chadtech$elm_money$Money$toNativeSymbol(currency);
		var dollars = cents / 100.0;
		return _Utils_ap(
			symbol,
			A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, dollars));
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Page$EditQuote$breakdownItem = F2(
	function (currency, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-end justify-between')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm font-medium text-gray-700')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(item.cb)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm text-gray-500')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cover: ' + item.cE.cu)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-sm font-medium text-gray-900')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Bill$formatMoney, currency, item.bK))
						]))
				]));
	});
var $elm$html$Html$dd = _VirtualDom_node('dd');
var $elm$html$Html$dl = _VirtualDom_node('dl');
var $elm$html$Html$dt = _VirtualDom_node('dt');
var $author$project$Page$EditQuote$breakdown = function (quote) {
	var _v0 = quote.N.cr;
	if (!_v0.b) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mt-4 bg-white border border-gray-200 rounded-lg shadow-sm')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('py-6 px-4 sm:px-6 text-sm text-gray-700')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('A product must be selected for all insured items.')
						]))
				]));
	} else {
		var lineItems = _v0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Breakdown of your insurance policy')
						])),
					A2(
					$elm$html$Html$dl,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('px-4 py-6 space-y-3 sm:px-6 border-b border-gray-200')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Page$EditQuote$breakdownItem(quote.N.ca),
								lineItems)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex items-center justify-between px-4 py-5 sm:px-6 ')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$dt,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-medium')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Premium')
										])),
									A2(
									$elm$html$Html$dd,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-medium text-gray-900')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$Bill$formatMoney, quote.N.ca, quote.N.cU))
										]))
								])),
							function () {
							var _v1 = A2($author$project$Page$EditQuote$billingCycleBreakdown, quote.b0, quote.N.b1);
							if (_v1.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var _v2 = _v1.a;
								var desc = _v2.a;
								var amount = _v2.b;
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center justify-between bg-orange-600 text-white px-4 py-5 sm:px-6 ')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm')
												]),
											_List_fromArray(
												[desc])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-medium')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2($author$project$Bill$formatMoney, quote.N.ca, amount))
												]))
										]));
							}
						}()
						]))
				]));
	}
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Page$EditQuote$sidebar = function (quote) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'timeline-title'),
				$elm$html$Html$Attributes$class('lg:col-start-3 lg:col-span-1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-white shadow sm:rounded-lg lg:sticky lg:top-2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('px-4 py-5 sm:px-6 border-b border-gray-200')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mt-1 max-w-2xl text-sm text-gray-500')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Your quote')
									])),
								A2(
								$elm$html$Html$h2,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
										$elm$html$Html$Attributes$id('applicant-information-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(quote.cv)
									]))
							])),
						$author$project$Page$EditQuote$breakdown(quote),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('py-6 px-4 sm:px-6 text-xs text-gray-400 mb-8')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Premiums include GST and Stamp Duty.')
							]))
					]))
			]));
};
var $author$project$Page$EditQuote$view = function (model) {
	var _v0 = model.f;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('min-h-full pt-16 pb-12 flex flex-col bg-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-shrink-0 flex justify-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('inline-flex')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-10 h-auto stroke-current text-gray-600 animate-spin', $feathericons$elm_feather$FeatherIcons$loader))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-14')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-600 uppercase tracking-wide')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Loading')
												])),
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Fetching your quote')
												]))
										]))
								]))
						]))
				]));
	} else {
		var quote = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$PurchasePathStep$purchasePathStepsNav,
					$elm$core$Maybe$Just(quote),
					_List_fromArray(
						[
							$author$project$PurchasePathStep$NewQuoteStep(2),
							$author$project$PurchasePathStep$ShowQuoteStep(2),
							$author$project$PurchasePathStep$EditQuoteStep(1),
							$author$project$PurchasePathStep$PayQuoteStep(0)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-6 lg:col-start-1 lg:col-span-2')
										]),
									_List_fromArray(
										[
											A5($author$project$Page$EditQuote$checkoutForm, quote, model.m, model.as, model.ab, model.al)
										])),
									$author$project$Page$EditQuote$sidebar(quote)
								]))
						]))
				]));
	}
};
var $author$project$Page$NewQuote$AddQuoteItem = {$: 1};
var $author$project$Page$NewQuote$SubmitForm = {$: 9};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$NewQuote$RemoveQuoteItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$NewQuote$SetQuoteItemName = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$NewQuote$SetCatalogue = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$NewQuote$catalogueOption = F2(
	function (item, cat) {
		var cId = $author$project$Catalogue$catalogueIdToString(cat.ck);
		var inputId = 'item' + ($elm$core$String$fromInt(item.ck) + ('catalogue' + cId));
		var isChecked = _Utils_eq(
			$author$project$Catalogue$catalogueIdToString(item.Y.ck),
			cId);
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-md cursor-pointer relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500', true),
							_Utils_Tuple2('hover:bg-gray-50', !isChecked),
							_Utils_Tuple2('bg-gray-200', isChecked)
						])),
					$elm$html$Html$Attributes$for(inputId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(cat.cu),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only'),
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$name(
							'item' + ($elm$core$String$fromInt(item.ck) + 'catalogue')),
							$elm$html$Html$Attributes$required(true),
							$elm$html$Html$Events$onInput(
							$author$project$Page$NewQuote$SetCatalogue(item)),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$value(cId),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$NewQuote$idSuffixString = F2(
	function (id, prefix) {
		return prefix + ('-' + $elm$core$String$fromInt(id));
	});
var $author$project$Page$NewQuote$UpdateParameterMonth = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Page$NewQuote$UpdateParameterYear = F3(
	function (a, b, c) {
		return {$: 7, a: a, b: b, c: c};
	});
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$minlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'minLength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$pattern = $elm$html$Html$Attributes$stringProperty('pattern');
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Page$NewQuote$daysRelativeInput = F2(
	function (item, param) {
		var yInputId = 'year' + $elm$core$String$fromInt(item.ck);
		var mInputId = 'month' + $elm$core$String$fromInt(item.ck);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Date of birth')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('sr-only'),
											$elm$html$Html$Attributes$for(mInputId)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Month')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
											$elm$html$Html$Attributes$id(mInputId),
											$elm$html$Html$Attributes$name(
											'item' + ($elm$core$String$fromInt(item.ck) + 'month')),
											$elm$html$Html$Attributes$type_('tel'),
											$elm$html$Html$Attributes$minlength(1),
											$elm$html$Html$Attributes$maxlength(2),
											$elm$html$Html$Attributes$placeholder('MM'),
											$elm$html$Html$Attributes$pattern('^((0?[1-9])|(1[0-2]))$'),
											$elm$html$Html$Attributes$value(
											A2($elm$core$Maybe$withDefault, '', param._)),
											$elm$html$Html$Events$onInput(
											A2($author$project$Page$NewQuote$UpdateParameterMonth, item, param)),
											$elm$html$Html$Attributes$required(true)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('sr-only'),
											$elm$html$Html$Attributes$for(yInputId)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Year')
										])),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
											$elm$html$Html$Attributes$id(yInputId),
											$elm$html$Html$Attributes$name(
											'item' + ($elm$core$String$fromInt(item.ck) + 'year')),
											$elm$html$Html$Attributes$type_('tel'),
											$elm$html$Html$Attributes$minlength(4),
											$elm$html$Html$Attributes$maxlength(4),
											$elm$html$Html$Attributes$placeholder('YYYY'),
											$elm$html$Html$Attributes$pattern('^\\d\\d\\d\\d$'),
											$elm$html$Html$Attributes$value(
											A2($elm$core$Maybe$withDefault, '', param.ag)),
											$elm$html$Html$Events$onInput(
											A2($author$project$Page$NewQuote$UpdateParameterYear, item, param)),
											$elm$html$Html$Attributes$required(true)
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$NewQuote$UpdateParameter = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Page$NewQuote$ratingFactorChosen = F2(
	function (param, band) {
		var _v0 = param.X;
		if (!_v0.$) {
			var chosen = _v0.a;
			return _Utils_eq(
				$author$project$Band$bandIdToString(chosen.ck),
				$author$project$Band$bandIdToString(band.ck));
		} else {
			return false;
		}
	});
var $author$project$Page$NewQuote$radioButtonInput = F3(
	function (item, param, band) {
		var isChecked = A2($author$project$Page$NewQuote$ratingFactorChosen, param, band);
		var inputId = 'item' + ($elm$core$String$fromInt(item.ck) + ('band' + $author$project$Band$bandIdToString(band.ck)));
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-md cursor-pointer relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500', true),
							_Utils_Tuple2('hover:bg-gray-50', !isChecked),
							_Utils_Tuple2('bg-gray-200', isChecked)
						])),
					$elm$html$Html$Attributes$for(inputId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(band.cu),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only'),
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$name(
							'item' + ($elm$core$String$fromInt(item.ck) + 'band')),
							$elm$html$Html$Attributes$required(true),
							$elm$html$Html$Events$onInput(
							A2($author$project$Page$NewQuote$UpdateParameter, item, param)),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$value(
							$author$project$Band$bandIdToString(band.ck)),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$NewQuote$radioButtonGroupInput = F2(
	function (item, param) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(param.i.cu)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6')
						]),
					A2(
						$elm$core$List$map,
						A2($author$project$Page$NewQuote$radioButtonInput, item, param),
						param.i.ah))
				]));
	});
var $author$project$Page$NewQuote$selectInput = F2(
	function (item, param) {
		var inputId = 'item' + ($elm$core$String$fromInt(item.ck) + ('factor' + $author$project$RatingFactor$ratingFactorIdToString(param.i.ck)));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex space-x-3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb-3')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-1'),
									$elm$html$Html$Attributes$for(inputId)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(param.i.cu)
								])),
							A2(
							$elm$html$Html$select,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'),
									$elm$html$Html$Attributes$id(inputId),
									$elm$html$Html$Attributes$name(
									'item' + ($elm$core$String$fromInt(item.ck) + 'factor')),
									$elm$html$Html$Attributes$required(true),
									$elm$html$Html$Events$onInput(
									A2($author$project$Page$NewQuote$UpdateParameter, item, param))
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$option,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('')
										])),
								A2(
									$elm$core$List$map,
									function (b) {
										return A2(
											$elm$html$Html$option,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(
													$author$project$Band$bandIdToString(b.ck)),
													$elm$html$Html$Attributes$selected(
													A2($author$project$Page$NewQuote$ratingFactorChosen, param, b))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(b.cu)
												]));
									},
									param.i.ah)))
						]))
				]));
	});
var $author$project$Page$NewQuote$parameterInput = F2(
	function (item, param) {
		var _v0 = param.i.cp;
		if (_v0 === 1) {
			return A2($author$project$Page$NewQuote$daysRelativeInput, item, param);
		} else {
			var options = $elm$core$List$length(param.i.ah);
			return (options < 9) ? A2($author$project$Page$NewQuote$radioButtonGroupInput, item, param) : A2($author$project$Page$NewQuote$selectInput, item, param);
		}
	});
var $author$project$Page$NewQuote$quoteItemForm = F3(
	function (catalogues, count, quoteItem) {
		var canDelete = count > 1;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('my-6 grid grid-cols-4 gap-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-span-2')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-1'),
											$elm$html$Html$Attributes$for(
											A2($author$project$Page$NewQuote$idSuffixString, quoteItem.ck, 'name'))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('My pet\'s name is')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-1')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'name'),
													$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
													$elm$html$Html$Attributes$id(
													A2($author$project$Page$NewQuote$idSuffixString, quoteItem.ck, 'name')),
													$elm$html$Html$Attributes$name(
													A2($author$project$Page$NewQuote$idSuffixString, quoteItem.ck, 'name')),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$value(quoteItem.cu),
													$elm$html$Html$Events$onInput(
													$author$project$Page$NewQuote$SetQuoteItemName(quoteItem)),
													$elm$html$Html$Attributes$required(true)
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-span-2')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-1')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('My pet is a')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6')
										]),
									A2(
										$elm$core$List$map,
										$author$project$Page$NewQuote$catalogueOption(quoteItem),
										catalogues))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									canDelete ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Page$NewQuote$RemoveQuoteItem(quoteItem)),
											$elm$html$Html$Attributes$class('inline-block bg-gray-100 border border-transparent rounded-md shadow-sm py-2 px-4 text-xs text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200'),
											$elm$html$Html$Attributes$type_('button')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Remove')
										])) : $elm$html$Html$text('')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Page$NewQuote$parameterInput(quoteItem),
						quoteItem.u))
				]));
	});
var $author$project$Page$NewQuote$SetState = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$NewQuote$stateOption = F2(
	function (chosen, state) {
		var isChecked = _Utils_eq(chosen, state);
		var inputId = 'state' + state;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-md cursor-pointer relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500', true),
							_Utils_Tuple2('hover:bg-gray-50', !isChecked),
							_Utils_Tuple2('bg-gray-200', isChecked)
						])),
					$elm$html$Html$Attributes$for(inputId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(state),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only'),
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$name('state'),
							$elm$html$Html$Attributes$required(true),
							$elm$html$Html$Events$onInput($author$project$Page$NewQuote$SetState),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$value(state),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$NewQuote$stateOptions = function (chosenState) {
	var states = _List_fromArray(
		['NSW', 'ACT', 'VIC', 'QLD', 'NT', 'TAS', 'WA', 'SA']);
	var chosen = A2($elm$core$Maybe$withDefault, '', chosenState);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700 mb-2'),
						$elm$html$Html$Attributes$for('state')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('What is your state of residence?')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('grid grid-cols-4 gap-2 md:grid-cols-8')
					]),
				A2(
					$elm$core$List$map,
					$author$project$Page$NewQuote$stateOption(chosen),
					states))
			]));
};
var $author$project$Page$NewQuote$quoteForm = F4(
	function (status, catalogues, chosenState, quoteItems) {
		var count = $elm$core$List$length(quoteItems);
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit($author$project$Page$NewQuote$SubmitForm)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('shadow sm:rounded-md sm:overflow-hidden')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm leading-6 text-gray-600 mb-0')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Step 1')
										])),
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Tell us about your pets')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('divide-y')
										]),
									A2(
										$elm$core$List$map,
										A2($author$project$Page$NewQuote$quoteItemForm, catalogues, count),
										quoteItems)),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Page$NewQuote$AddQuoteItem),
											$elm$html$Html$Attributes$class('bg-gray-100 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300'),
											$elm$html$Html$Attributes$type_('button')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Add another pet')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mb-3 text-base leading-6 font-bold text-gray-700')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Your details')
												])),
											$author$project$Page$NewQuote$stateOptions(chosenState)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-6 px-4 bg-gray-50 sm:px-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-xs mb-6 font-light text-slate-500')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('By proceeding to obtain a quote you agree that you understand and accept the Terms &amp; Conditions, Financial Services Guide,  Privacy Policy and agree to our use of cookies. We may pay referral fees or benefits to individuals or organisations that refer new customers to us. You agree that we may contact you about the services we provides.')
										])),
									function () {
									switch (status.$) {
										case 5:
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('bg-gray-400 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-base font-medium text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Get a quote')
													]));
										case 3:
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-base font-medium text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Submitting…')
													]));
										default:
											return A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'),
														$elm$html$Html$Attributes$type_('submit')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Get a quote')
													]));
									}
								}()
								]))
						]))
				]));
	});
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$polyline = $elm$svg$Svg$trustedNode('polyline');
var $feathericons$elm_feather$FeatherIcons$check = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'check',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('20 6 9 17 4 12')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$fileText = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'file-text',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('14 2 14 8 20 8')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16'),
					$elm$svg$Svg$Attributes$y1('13'),
					$elm$svg$Svg$Attributes$x2('8'),
					$elm$svg$Svg$Attributes$y2('13')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16'),
					$elm$svg$Svg$Attributes$y1('17'),
					$elm$svg$Svg$Attributes$x2('8'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('10 9 9 9 8 9')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$messageSquare = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'message-square',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$phone = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'phone',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z')
				]),
			_List_Nil)
		]));
var $author$project$Page$NewQuote$sidebar = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'timeline-title'),
			$elm$html$Html$Attributes$class('lg:col-start-3 lg:col-span-1')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-white shadow sm:rounded-lg')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('px-4 py-5 sm:px-6 border-b border-gray-200')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-1 max-w-2xl text-sm text-gray-500')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Key benefits')
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
									$elm$html$Html$Attributes$id('applicant-information-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('What you need to know')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-3 px-4 py-5 sm:px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dl,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-green-500 absolute', $feathericons$elm_feather$FeatherIcons$check)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-900')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Four plans to choose from')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-500')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Select the cover suited to your needs.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-green-500 absolute', $feathericons$elm_feather$FeatherIcons$check)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-900')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Up to 80% of eligible bills reimbursed')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-500')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Options from 60-80% of eligable bills covered.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-green-500 absolute', $feathericons$elm_feather$FeatherIcons$check)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-900')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Flexible excess')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-500')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Choose your excess level to suit you.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-green-500 absolute', $feathericons$elm_feather$FeatherIcons$check)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-900')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Lifetime cover')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-500')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Genuine cover for life.')
												]))
										]))
								]))
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-gray-500 mt-8 shadow sm:rounded-lg')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('px-4 py-5 sm:px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-1 max-w-2xl text-sm text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Key documents')
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-white'),
									$elm$html$Html$Attributes$id('applicant-information-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('What you need to know')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-0 px-4 py-5 sm:px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dl,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$fileText)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Download our PDS')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('It\'s the best way to understand what\'s covered.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$messageSquare)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Instant help online')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Start a chat with us down below.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$phone)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Call us on 1300 000 000')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Have questions... we jave answers.')
												]))
										]))
								]))
						]))
				]))
		]));
var $author$project$Page$NewQuote$view = function (model) {
	var _v0 = model.m;
	if (!_v0.$) {
		return $elm$html$Html$text('Loading...');
	} else {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$PurchasePathStep$purchasePathStepsNav,
					$elm$core$Maybe$Nothing,
					_List_fromArray(
						[
							$author$project$PurchasePathStep$NewQuoteStep(1),
							$author$project$PurchasePathStep$ShowQuoteStep(0),
							$author$project$PurchasePathStep$EditQuoteStep(0),
							$author$project$PurchasePathStep$PayQuoteStep(0)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-6 lg:col-start-1 lg:col-span-2')
										]),
									_List_fromArray(
										[
											function () {
											var _v1 = model.m;
											if (_v1.$ === 5) {
												var err = _v1.a;
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('mb-4')
														]),
													_List_fromArray(
														[
															$author$project$ErrorResponse$errorNotification(err)
														]));
											} else {
												return $elm$html$Html$text('');
											}
										}(),
											function () {
											var _v2 = model.P;
											if (!_v2.$) {
												var catalogues = _v2.a;
												return A4($author$project$Page$NewQuote$quoteForm, model.m, catalogues, model.bG, model.h);
											} else {
												return $elm$html$Html$text('');
											}
										}()
										])),
									$author$project$Page$NewQuote$sidebar
								]))
						]))
				]));
	}
};
var $author$project$Page$PayQuote$errorAlert = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt-3 rounded-md bg-red-50 p-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-shrink-0')
							]),
						_List_fromArray(
							[
								A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-red-400', $feathericons$elm_feather$FeatherIcons$alertTriangle))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-3')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-sm font-medium text-red-800')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(err)
									]))
							]))
					]))
			]));
};
var $author$project$Page$PayQuote$SetBillingName = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$PayQuote$SubmitForm = {$: 9};
var $author$project$Page$PayQuote$catalogueLabel = function (name) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-2.5 py-0.5 rounded-md text-xs font-bold bg-white text-gray-800 whitespace-nowrap')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(name)
			]));
};
var $author$project$Page$PayQuote$chargeCopy = F2(
	function (today, d) {
		return (A2($justinmimbs$date$Date$compare, today, d) === 1) ? 'We will charge your card immediately' : ('We will charge your card on ' + A2($justinmimbs$date$Date$format, 'd MMM YYYY', d));
	});
var $author$project$Page$PayQuote$describeParameter = function (param) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-2.5 py-0.5 rounded-md text-xs font-medium bg-white text-gray-800 whitespace-nowrap inline-flex')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inline-block text-gray-600 mr-1.5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(param.i.cu + ': ')
					])),
				$elm$html$Html$text(param.X.cu)
			]));
};
var $elm$html$Html$Attributes$scope = $elm$html$Html$Attributes$stringProperty('scope');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$PayQuote$paymentForm = F5(
	function (quote, status, today, billingName, lastStripeEvent) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit($author$project$Page$PayQuote$SubmitForm)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('shadow sm:rounded-md sm:overflow-hidden')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6 border-b border-gray-200')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm leading-6 text-gray-600 mb-0')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Step 4')
										])),
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Confirm & pay')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6')
								]),
							_List_fromArray(
								[
									function () {
									if (!status.$) {
										return $elm$html$Html$text('Preparing payment...');
									} else {
										return A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$table,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-full text-gray-500')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$thead,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('sr-only text-sm text-gray-500 text-left sm:not-sr-only')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$tr,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$th,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('pr-8 py-3 font-normal'),
																					$elm$html$Html$Attributes$scope('col')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Pet')
																				])),
																			A2(
																			$elm$html$Html$th,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('pr-8 py-3 font-normal sm:table-cell'),
																					$elm$html$Html$Attributes$scope('col')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Details')
																				])),
																			A2(
																			$elm$html$Html$th,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('pr-8 py-3 font-normal sm:table-cell'),
																					$elm$html$Html$Attributes$scope('col')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Plan')
																				]))
																		]))
																])),
															A2(
															$elm$html$Html$tbody,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('border-gray-200 divide-y divide-gray-200 text-sm sm:border-t')
																]),
															A2(
																$elm$core$List$map,
																function (item) {
																	var _v1 = _Utils_Tuple2(item.cu, item.cJ);
																	if ((!_v1.a.$) && (!_v1.b.$)) {
																		var name = _v1.a.a;
																		var product = _v1.b.a;
																		return A2(
																			$elm$html$Html$tr,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$td,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('align-top py-6 pr-8 font-medium text-gray-900')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(name)
																						])),
																					A2(
																					$elm$html$Html$td,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('align-top py-6 pr-8 font-medium text-gray-500')
																						]),
																					A2(
																						$elm$core$List$cons,
																						$author$project$Page$PayQuote$catalogueLabel(item.b6),
																						A2($elm$core$List$map, $author$project$Page$PayQuote$describeParameter, item.u))),
																					A2(
																					$elm$html$Html$td,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('align-top py-6 pr-8 font-medium text-gray-900')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(product.cu)
																						]))
																				]));
																	} else {
																		return $elm$html$Html$text('');
																	}
																},
																quote.h))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('pt-6 border-t border-gray-200')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700 mb-6')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Confirming your policy')
																])),
															function () {
															var _v2 = _Utils_Tuple2(today, quote.ao);
															if ((!_v2.a.$) && (!_v2.b.$)) {
																var t = _v2.a.a;
																var s = _v2.b.a;
																var d = A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, s);
																return A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('text-sm font-medium mb-6')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(
																			A2($author$project$Page$PayQuote$chargeCopy, t, d))
																		]));
															} else {
																return $elm$html$Html$text('');
															}
														}(),
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
																	$elm$html$Html$Attributes$for('billingName')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Name on card')
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-2')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
																			$elm$html$Html$Attributes$id('billingName'),
																			$elm$html$Html$Attributes$name('billingName'),
																			$elm$html$Html$Attributes$type_('text'),
																			$elm$html$Html$Attributes$value(billingName),
																			$elm$html$Html$Events$onInput($author$project$Page$PayQuote$SetBillingName),
																			$elm$html$Html$Attributes$required(true)
																		]),
																	_List_Nil),
																	A2(
																	$elm$html$Html$label,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('mt-3 block text-sm font-medium text-gray-700'),
																			$elm$html$Html$Attributes$for('card-element')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Payment details')
																		]))
																]))
														]))
												]));
									}
								}(),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$id('card-element')
												]),
											_List_Nil)
										])),
									function () {
									if (status.$ === 4) {
										var err = status.a;
										return $author$project$Page$PayQuote$errorAlert(err);
									} else {
										return $elm$html$Html$text('');
									}
								}(),
									function () {
									if (lastStripeEvent.$ === 1) {
										return $elm$html$Html$text('');
									} else {
										var event = lastStripeEvent.a;
										var _v5 = event.av;
										if (_v5.$ === 1) {
											return $elm$html$Html$text('');
										} else {
											var err = _v5.a;
											return $author$project$Page$PayQuote$errorAlert(err.bf);
										}
									}
								}()
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-6 px-4 bg-gray-50 sm:px-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-xs mb-6 font-light text-slate-500')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('By continuing you agree and acknowledge you have read and understood your Your Duty to take Reasonable Care not to Misrepresent, the Policy Disclosure Statement and that the benefits of the plans you have selected are right for you and your pets.')
										])),
									function () {
									switch (status.$) {
										case 0:
											return $elm$html$Html$text('');
										case 2:
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('inline-block cursor-not-allowed bg-green-700 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Submitting...')
													]));
										case 3:
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('inline-block cursor-not-allowed bg-green-700 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Done')
													]));
										case 5:
											return $elm$html$Html$text('');
										default:
											if (lastStripeEvent.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var event = lastStripeEvent.a;
												return event.aj ? A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('inline-block bg-green-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-500'),
															$elm$html$Html$Attributes$type_('submit')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Complete payment')
														])) : A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('inline-block cursor-not-allowed bg-gray-400 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Complete payment')
														]));
											}
									}
								}()
								]))
						]))
				]));
	});
var $feathericons$elm_feather$FeatherIcons$creditCard = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'credit-card',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('1'),
					$elm$svg$Svg$Attributes$y('4'),
					$elm$svg$Svg$Attributes$width('22'),
					$elm$svg$Svg$Attributes$height('16'),
					$elm$svg$Svg$Attributes$rx('2'),
					$elm$svg$Svg$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('1'),
					$elm$svg$Svg$Attributes$y1('10'),
					$elm$svg$Svg$Attributes$x2('23'),
					$elm$svg$Svg$Attributes$y2('10')
				]),
			_List_Nil)
		]));
var $author$project$Page$PayQuote$processingModal = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'modal-title'),
			A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'),
			$elm$html$Html$Attributes$class('fixed z-10 inset-0 overflow-y-auto'),
			A2($elm$html$Html$Attributes$attribute, 'role', 'dialog')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('hidden sm:inline-block sm:align-middle sm:h-screen')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\u200B')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-600 animate-pulse', $feathericons$elm_feather$FeatherIcons$creditCard))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-3 text-center sm:mt-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
													$elm$html$Html$Attributes$id('modal-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Payment processing')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-sm text-gray-500')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Your payment is being processed. This may take a few moments to complete.')
														]))
												]))
										]))
								]))
						]))
				]))
		]));
var $author$project$Page$PayQuote$describeBillingCycleOption = function (o) {
	var _v0 = o.cW;
	if (!_v0) {
		return _Utils_Tuple2(
			A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold block')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Billing monthly')
							])),
						$elm$html$Html$text('12 monthly payments')
					])),
			o.cA);
	} else {
		return _Utils_Tuple2(
			A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold block')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Billing annually')
							])),
						$elm$html$Html$text('1 annual payment')
					])),
			o.cA);
	}
};
var $author$project$Page$PayQuote$findBillingCycleOption = F2(
	function (options, c) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (o) {
				return _Utils_eq(o.cW, c);
			},
			options);
	});
var $author$project$Page$PayQuote$billingCycleBreakdown = F2(
	function (chosen, options) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$Page$PayQuote$describeBillingCycleOption,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Page$PayQuote$findBillingCycleOption(options),
				chosen));
	});
var $author$project$Page$PayQuote$breakdownItem = F2(
	function (currency, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-end justify-between')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm font-medium text-gray-700')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(item.cb)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm text-gray-500')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cover: ' + item.cE.cu)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-sm font-medium text-gray-900')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Bill$formatMoney, currency, item.bK))
						]))
				]));
	});
var $author$project$Page$PayQuote$breakdown = function (quote) {
	var _v0 = quote.N.cr;
	if (!_v0.b) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mt-4 bg-white border border-gray-200 rounded-lg shadow-sm')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('py-6 px-4 sm:px-6 text-sm text-gray-700')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('A product must be selected for all insured items.')
						]))
				]));
	} else {
		var lineItems = _v0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sr-only')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Breakdown of your insurance policy')
						])),
					A2(
					$elm$html$Html$dl,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('px-4 py-6 space-y-3 sm:px-6 border-b border-gray-200')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Page$PayQuote$breakdownItem(quote.N.ca),
								lineItems)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex items-center justify-between px-4 py-5 sm:px-6 ')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$dt,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-medium')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Premium')
										])),
									A2(
									$elm$html$Html$dd,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-medium text-gray-900')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$Bill$formatMoney, quote.N.ca, quote.N.cU))
										]))
								])),
							function () {
							var _v1 = A2($author$project$Page$PayQuote$billingCycleBreakdown, quote.b0, quote.N.b1);
							if (_v1.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var _v2 = _v1.a;
								var desc = _v2.a;
								var amount = _v2.b;
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center justify-between bg-orange-600 text-white px-4 py-5 sm:px-6 ')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm')
												]),
											_List_fromArray(
												[desc])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-medium')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2($author$project$Bill$formatMoney, quote.N.ca, amount))
												]))
										]));
							}
						}(),
							function () {
							var _v3 = quote.ao;
							if (!_v3.$) {
								var s = _v3.a;
								var d = A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, s);
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center justify-between bg-orange-600 text-white px-4 py-5 sm:px-6 ')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Policy begins')
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-medium')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2($justinmimbs$date$Date$format, 'EEEE d MMMM YYYY', d))
												]))
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]))
				]));
	}
};
var $author$project$Page$PayQuote$sidebar = function (quote) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'timeline-title'),
				$elm$html$Html$Attributes$class('lg:col-start-3 lg:col-span-1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-white shadow sm:rounded-lg')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('px-4 py-5 sm:px-6 border-b border-gray-200')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mt-1 max-w-2xl text-sm text-gray-500')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Your quote')
									])),
								A2(
								$elm$html$Html$h2,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
										$elm$html$Html$Attributes$id('applicant-information-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(quote.cv)
									]))
							])),
						$author$project$Page$PayQuote$breakdown(quote),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('py-6 px-4 space-y-6 sm:px-6 text-xs text-gray-400 mb-8')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Premiums include GST and Stamp Duty.')
							]))
					]))
			]));
};
var $author$project$Page$PayQuote$view = function (model) {
	var _v0 = model.f;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('min-h-full pt-16 pb-12 flex flex-col bg-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-shrink-0 flex justify-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('inline-flex'),
											$elm$html$Html$Attributes$href('/')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-10 h-auto stroke-current text-gray-600 animate-spin', $feathericons$elm_feather$FeatherIcons$loader))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-14')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-600 uppercase tracking-wide')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Loading')
												])),
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Fetching your quote')
												]))
										]))
								]))
						]))
				]));
	} else {
		var quote = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$PurchasePathStep$purchasePathStepsNav,
					$elm$core$Maybe$Just(quote),
					_List_fromArray(
						[
							$author$project$PurchasePathStep$NewQuoteStep(2),
							$author$project$PurchasePathStep$ShowQuoteStep(2),
							$author$project$PurchasePathStep$EditQuoteStep(2),
							$author$project$PurchasePathStep$PayQuoteStep(1)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-6 lg:col-start-1 lg:col-span-2')
										]),
									_List_fromArray(
										[
											function () {
											var _v1 = model.s;
											if (_v1.$ === 5) {
												var err = _v1.a;
												return $author$project$Page$PayQuote$errorAlert(err);
											} else {
												return A5($author$project$Page$PayQuote$paymentForm, quote, model.s, model.as, model.O, model.S);
											}
										}()
										])),
									$author$project$Page$PayQuote$sidebar(quote),
									function () {
									var _v2 = model.s;
									switch (_v2.$) {
										case 3:
											return $author$project$Page$PayQuote$processingModal;
										case 2:
											return $author$project$Page$PayQuote$processingModal;
										default:
											return $elm$html$Html$text('');
									}
								}()
								]))
						]))
				]));
	}
};
var $author$project$Page$PolicyBilling$loadingScreen = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('min-h-full pt-16 pb-12 flex flex-col bg-white')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-shrink-0 flex justify-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('inline-flex')
								]),
							_List_fromArray(
								[
									A2(
									$feathericons$elm_feather$FeatherIcons$toHtml,
									_List_Nil,
									A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-10 h-auto stroke-current text-gray-600 animate-spin', $feathericons$elm_feather$FeatherIcons$loader))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('py-14')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-600 uppercase tracking-wide')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Loading')
										])),
									A2(
									$elm$html$Html$h1,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Fetching your policy')
										]))
								]))
						]))
				]))
		]));
var $feathericons$elm_feather$FeatherIcons$checkSquare = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'check-square',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('9 11 12 14 22 4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11')
				]),
			_List_Nil)
		]));
var $author$project$Page$PolicyBilling$paymentCompleteModal = function (policy) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'modal-title'),
				A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'),
				$elm$html$Html$Attributes$class('fixed z-10 inset-0 overflow-y-auto'),
				A2($elm$html$Html$Attributes$attribute, 'role', 'dialog')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('hidden sm:inline-block sm:align-middle sm:h-screen')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('\u200B')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100')
											]),
										_List_fromArray(
											[
												A2(
												$feathericons$elm_feather$FeatherIcons$toHtml,
												_List_Nil,
												A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-green-600 animate-pulse', $feathericons$elm_feather$FeatherIcons$checkSquare))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mt-3 text-center sm:mt-5')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h3,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
														$elm$html$Html$Attributes$id('modal-title')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Payment complete')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mt-2')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('text-sm text-gray-500')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Your payment has completed. Use the button below to view your policy\'s certificate.')
															]))
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mt-5 sm:mt-6')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'),
												$elm$html$Html$Attributes$href(
												$author$project$Route$routeToString(
													$author$project$Route$ShowPolicy(policy.ck)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Your policy')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$PolicyBilling$SetBillingName = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PolicyBilling$SubmitForm = {$: 7};
var $author$project$Page$PolicyBilling$errorAlert = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt-3 rounded-md bg-red-50 p-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-shrink-0')
							]),
						_List_fromArray(
							[
								A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-red-400', $feathericons$elm_feather$FeatherIcons$alertTriangle))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-3')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-sm font-medium text-red-800')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(err)
									]))
							]))
					]))
			]));
};
var $author$project$Page$PolicyBilling$paymentForm = F3(
	function (s, billingName, lastStripeEvent) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mt-5'),
					$elm$html$Html$Events$onSubmit($author$project$Page$PolicyBilling$SubmitForm)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							if (!s.$) {
								return $elm$html$Html$text('Account up to date');
							} else {
								return A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('block text-base font-medium text-gray-700 mb-5')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Enter card details below to add a new payment method to your account and process payment again.')
												])),
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('block text-sm font-medium text-gray-700'),
													$elm$html$Html$Attributes$for('billingName')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Name on card')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'),
															$elm$html$Html$Attributes$id('billingName'),
															$elm$html$Html$Attributes$name('billingName'),
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$value(billingName),
															$elm$html$Html$Events$onInput($author$project$Page$PolicyBilling$SetBillingName),
															$elm$html$Html$Attributes$required(true)
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('mt-3 block text-sm font-medium text-gray-700'),
															$elm$html$Html$Attributes$for('card-element')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Payment details')
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$id('card-element')
														]),
													_List_Nil)
												]))
										]));
							}
						}(),
							function () {
							if (lastStripeEvent.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var event = lastStripeEvent.a;
								var _v2 = event.av;
								if (_v2.$ === 1) {
									return $elm$html$Html$text('');
								} else {
									var err = _v2.a;
									return $author$project$Page$PolicyBilling$errorAlert(err.bf);
								}
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									function () {
									switch (s.$) {
										case 0:
											return $elm$html$Html$text('');
										case 2:
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-center w-full cursor-not-allowed bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-gray-800'),
														$elm$html$Html$Attributes$type_('submit')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Submitting...')
													]));
										case 3:
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-center w-full cursor-not-allowed bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-gray-800'),
														$elm$html$Html$Attributes$type_('submit')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Complete')
													]));
										case 4:
											var err = s.a;
											return $author$project$Page$PolicyBilling$errorAlert(err);
										default:
											if (lastStripeEvent.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var event = lastStripeEvent.a;
												return event.aj ? A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-full bg-gray-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500'),
															$elm$html$Html$Attributes$type_('submit')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Complete payment')
														])) : A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-center block cursor-not-allowed w-full bg-gray-300 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white'),
															$elm$html$Html$Attributes$type_('submit')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Complete payment')
														]));
											}
									}
								}()
								]))
						]))
				]));
	});
var $author$project$Page$PolicyBilling$paymentProcessingModal = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'modal-title'),
			A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'),
			$elm$html$Html$Attributes$class('fixed z-10 inset-0 overflow-y-auto'),
			A2($elm$html$Html$Attributes$attribute, 'role', 'dialog')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('hidden sm:inline-block sm:align-middle sm:h-screen')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\u200B')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-600 animate-pulse', $feathericons$elm_feather$FeatherIcons$creditCard))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-3 text-center sm:mt-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
													$elm$html$Html$Attributes$id('modal-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Payment processing')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-sm text-gray-500')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Your payment is being processed. This may take a few moments to complete.')
														]))
												]))
										]))
								]))
						]))
				]))
		]));
var $author$project$Page$PolicyBilling$paymentsOutstandingNotice = function (s) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt-3 rounded-md bg-orange-50 p-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-shrink-0')
							]),
						_List_fromArray(
							[
								A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-red-400', $feathericons$elm_feather$FeatherIcons$creditCard))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-3')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-sm font-medium text-red-800')
									]),
								_List_fromArray(
									[
										function () {
										switch (s) {
											case 0:
												return $elm$html$Html$text('There was a problem processing the payment for your new policy.');
											case 1:
												return $elm$html$Html$text('There was a problem processing the most recent payment on your account.');
											default:
												return $elm$html$Html$text('');
										}
									}()
									]))
							]))
					]))
			]));
};
var $author$project$Policy$policyNumberToString = function (_v0) {
	var number = _v0;
	return number;
};
var $author$project$Page$PolicyBilling$view = function (model) {
	var _v0 = model.C;
	if (_v0.$ === 1) {
		return $author$project$Page$PolicyBilling$loadingScreen;
	} else {
		var policy = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sm:mt-8 sm:mx-auto md:w-2/3 lg:w-1/2 bg-white px-4 py-5 shadow md:rounded sm:px-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ml-4 mt-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Payments')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex text-sm mt-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-500 mr-2')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Policy number')
												])),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-medium text-gray-800')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Policy$policyNumberToString(policy.cv))
												]))
										]))
								]))
						])),
					function () {
					var _v1 = $author$project$Page$PolicyBilling$billingStatus(policy.cB);
					if (_v1.$ === 2) {
						return $author$project$Page$PolicyBilling$paymentsOutstandingNotice(policy.m);
					} else {
						return $elm$html$Html$text('');
					}
				}(),
					function () {
					var _v2 = model.s;
					switch (_v2.$) {
						case 2:
							return $author$project$Page$PolicyBilling$paymentProcessingModal;
						case 3:
							return $author$project$Page$PolicyBilling$paymentCompleteModal(policy);
						default:
							return $elm$html$Html$text('');
					}
				}(),
					A3($author$project$Page$PolicyBilling$paymentForm, model.s, model.O, model.S)
				]));
	}
};
var $author$project$Page$ShowPolicy$FetchCertificate = {$: 2};
var $author$project$Page$ShowPolicy$catalogueLabel = function (name) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-2.5 py-0.5 rounded-md text-xs font-bold bg-white text-gray-800')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(name)
			]));
};
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$ShowPolicy$describeParameter = function (param) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-2.5 py-0.5 rounded-md text-xs font-medium bg-white text-gray-800')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inline-block text-gray-600 mr-1.5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(param.i.cu + ': ')
					])),
				$elm$html$Html$text(param.X.cu)
			]));
};
var $author$project$Page$ShowPolicy$findBillingCycleOption = F2(
	function (options, c) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (o) {
				return _Utils_eq(o.cW, c);
			},
			options);
	});
var $author$project$Page$ShowPolicy$toUtcString = function (time) {
	var d = A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, time);
	return A2($justinmimbs$date$Date$format, 'd MMM YYYY', d);
};
var $author$project$Page$ShowPolicy$paymentDescription = function (policy) {
	var when = $author$project$Page$ShowPolicy$toUtcString(policy.cC.cL);
	var amount = A2(
		$author$project$Bill$formatMoney,
		policy.N.ca,
		A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.cA;
				},
				A2($author$project$Page$ShowPolicy$findBillingCycleOption, policy.N.b1, policy.b0))));
	return A2(
		$elm$html$Html$span,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Your '),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Bill$billingCycleUnitToString(policy.b0))
					])),
				$elm$html$Html$text(' premium of '),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(amount)
					])),
				$elm$html$Html$text(' will be debited on '),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(when)
					])),
				$elm$html$Html$text('.')
			]));
};
var $author$project$Page$ShowPolicy$paymentStatusLabel = function (s) {
	if (!s) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mr-2 px-2.5 py-0.5 rounded-md font-medium bg-green-100 text-gray-800')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Paid')
				]));
	} else {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mr-2 px-2.5 py-0.5 rounded-md font-medium bg-red-100 text-gray-800')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Failed')
				]));
	}
};
var $feathericons$elm_feather$FeatherIcons$downloadCloud = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'download-cloud',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('8 17 12 21 16 17')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('21')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29')
				]),
			_List_Nil)
		]));
var $author$project$Page$ShowPolicy$processingModal = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'modal-title'),
			A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'),
			$elm$html$Html$Attributes$class('fixed z-10 inset-0 overflow-y-auto'),
			A2($elm$html$Html$Attributes$attribute, 'role', 'dialog')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('hidden sm:inline-block sm:align-middle sm:h-screen')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\u200B')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-600 animate-pulse', $feathericons$elm_feather$FeatherIcons$downloadCloud))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-3 text-center sm:mt-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900'),
													$elm$html$Html$Attributes$id('modal-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Fetching certificate')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-sm text-gray-500')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Your Certificate of Insurance PDF is being retrieved.')
														]))
												]))
										]))
								]))
						]))
				]))
		]));
var $author$project$Page$ShowPolicy$sentenceNames = function (lines) {
	if (!lines.b) {
		return $elm$html$Html$text('your pets. ');
	} else {
		if (!lines.b.b) {
			var pet = lines.a;
			return $elm$html$Html$text(pet.cu + '. ');
		} else {
			var pet = lines.a;
			var rest = lines.b;
			var some = A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function ($) {
						return $.cu;
					},
					rest));
			return $elm$html$Html$text(some + (' & ' + (pet.cu + '. ')));
		}
	}
};
var $author$project$Page$ShowPolicy$sidebar = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('lg:col-start-3 lg:col-span-1')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-gray-500 shadow sm:rounded-lg')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('px-4 py-5 sm:px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-1 max-w-2xl text-sm text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Key documents')
								])),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-white')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('What you need to know')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-0 px-4 py-5 sm:px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dl,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$fileText)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Download our PDS')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('It\'s the best way to understand what\'s covered.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$messageSquare)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Instant help online')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Start a chat with us down below.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative mb-4')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dt,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withClass, 'h-6 w-auto stroke-current text-gray-200 absolute', $feathericons$elm_feather$FeatherIcons$phone)),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('ml-9 text-mdleading-2 font-medium text-gray-200')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Call us on 1300 000 000')
														]))
												])),
											A2(
											$elm$html$Html$dd,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-2 ml-9 text-sm text-gray-300')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Have questions... we jave answers.')
												]))
										]))
								]))
						]))
				]))
		]));
var $elm$html$Html$tfoot = _VirtualDom_node('tfoot');
var $author$project$Page$ShowPolicy$timeRange = function (range) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$ShowPolicy$toUtcString(range.cL))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inline-block px-1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('-')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$ShowPolicy$toUtcString(range.ch))
					]))
			]));
};
var $author$project$Bill$taxKindString = function (kind) {
	if (!kind) {
		return 'Stamp duty';
	} else {
		return 'GST';
	}
};
var $author$project$Page$ShowPolicy$totalRows = function (b) {
	var taxes = A2(
		$elm$core$List$map,
		function (t) {
			return _Utils_Tuple3(
				false,
				$author$project$Bill$taxKindString(t.cp),
				t.bW);
		},
		b.cR);
	return _Utils_ap(
		taxes,
		_List_fromArray(
			[
				_Utils_Tuple3(true, 'Total', b.cU)
			]));
};
var $author$project$Page$ShowPolicy$view = function (model) {
	var _v0 = model.C;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('min-h-full pt-16 pb-12 flex flex-col bg-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-shrink-0 flex justify-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('inline-flex'),
											$elm$html$Html$Attributes$href('/')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-10 h-auto stroke-current text-gray-600 animate-spin', $feathericons$elm_feather$FeatherIcons$loader))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-14')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-600 uppercase tracking-wide')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Loading')
												])),
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Fetching your policy details')
												])),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-4 text-xl text-gray-500')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('If you just purchased your policy, this may take a few moments.')
												]))
										]))
								]))
						]))
				]));
	} else {
		var policy = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('space-y-6 lg:col-start-1 lg:col-span-2')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('shadow sm:rounded-md sm:overflow-hidden')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6 border-b border-gray-200')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h2,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-sm leading-6 text-gray-600 mb-0')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Order complete')
																])),
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-base leading-6 font-bold text-gray-700')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('We have successfully processed your policy')
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('bg-white py-6 px-4 sm:p-6')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-lg leading-6 font-medium text-gray-900 mb-2')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('About your policy')
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-gray-700 text-sm')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Congratulations on being a great pet parent and taking out your policy for '),
																	$author$project$Page$ShowPolicy$sentenceNames(policy.bm)
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-2 text-gray-700 text-sm')
																]),
															_List_fromArray(
																[
																	$author$project$Page$ShowPolicy$paymentDescription(policy)
																])),
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-6 mb-2 text-lg leading-6 font-medium text-gray-900')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('We\'ve sent a welcome pack to ' + policy.e.aY)
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-gray-700 text-sm')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Your pet insurance policy can give you peace of mind and assist you in providing the best care if the need arises. When you first start your policy, there are a few things you need to know. Included in your welcome pack you\'ll find:            ')
																])),
															A2(
															$elm$html$Html$ol,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('list-decimal text-gray-700 text-sm my-3 pl-10')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Your Certificate of Insurance')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('The Combined Product Disclosure Statement and Financial Services Guide (PDS/FSG)')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('The terms of your insurance cover and your renewal offer, including changes to your policy and premium')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Please ensure you read the PDS carefully to consider if this product is right for you and to understand what you are covered for under your policy')
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mb-3')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$button,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('bg-white mt-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'),
																			$elm$html$Html$Events$onClick($author$project$Page$ShowPolicy$FetchCertificate)
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Download certificate PDF')
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('grid grid-flow-col text-sm text-gray-600 my-6 pt-6 px-4 border-t border-gray-200')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('font-bold text-gray-900')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Policy number')
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Policy$policyNumberToString(policy.cv))
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('font-bold text-gray-900 mt-1')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Period of cover')
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$author$project$Page$ShowPolicy$timeRange(policy.cC)
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('font-bold text-gray-900')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Policy holder')
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(policy.e.cu)
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(policy.e.aY)
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(policy.e.bk)
																				]))
																		]))
																])),
															function () {
															var _v1 = $elm$core$List$head(policy.cB);
															if (_v1.$ === 1) {
																return $elm$html$Html$text('');
															} else {
																var payment = _v1.a;
																var at = A2($elm$core$Maybe$withDefault, payment.bY, payment.cz);
																return A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('bg-gray-50 rounded-lg py-6 px-4 sm:px-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 lg:space-x-8')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$dl,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('divide-y divide-gray-200 space-y-6 text-sm text-gray-600 flex-auto sm:divide-y-0 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 lg:w-1/2 lg:flex-none lg:gap-x-8')
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('flex justify-between sm:block')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$dt,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('font-medium text-gray-900')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text('Date placed')
																								])),
																							A2(
																							$elm$html$Html$dd,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('sm:mt-1')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(
																									$author$project$Page$ShowPolicy$toUtcString(at))
																								]))
																						])),
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('flex justify-between pt-6 sm:block sm:pt-0')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$div,
																							_List_Nil,
																							_List_fromArray(
																								[
																									$author$project$Page$ShowPolicy$paymentStatusLabel(payment.m)
																								])),
																							A2(
																							$elm$html$Html$div,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('mt-1 uppercase')
																								]),
																							_List_fromArray(
																								[
																									$elm$html$Html$text(payment.bj.b3 + (' ' + payment.bj.cq))
																								]))
																						]))
																				])),
																			function () {
																			var _v2 = payment.cO;
																			if (!_v2.$) {
																				var u = _v2.a;
																				return A2(
																					$elm$html$Html$a,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('w-full flex items-center justify-center bg-white mt-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:mt-0'),
																							$elm$html$Html$Attributes$href(u)
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text('View receipt')
																						]));
																			} else {
																				return $elm$html$Html$text('');
																			}
																		}()
																		]));
															}
														}(),
															A2(
															$elm$html$Html$table,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-4 w-full text-gray-500 sm:mt-6')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$thead,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('sr-only text-sm text-gray-500 text-left sm:not-sr-only')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$tr,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$th,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('pr-8 py-3 font-normal'),
																							$elm$html$Html$Attributes$scope('col')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text('Pet')
																						])),
																					A2(
																					$elm$html$Html$th,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('pr-8 py-3 font-normal sm:table-cell'),
																							$elm$html$Html$Attributes$scope('col')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text('Details')
																						])),
																					A2(
																					$elm$html$Html$th,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('pr-8 py-3 font-normal sm:table-cell'),
																							$elm$html$Html$Attributes$scope('col')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text('Plan')
																						])),
																					A2(
																					$elm$html$Html$th,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('w-0 py-3 font-normal text-right pr-8'),
																							$elm$html$Html$Attributes$scope('col')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text('Total')
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$tbody,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('border-b border-gray-200 divide-y divide-gray-200 text-sm sm:border-t')
																		]),
																	A2(
																		$elm$core$List$map,
																		function (line) {
																			return A2(
																				$elm$html$Html$tr,
																				_List_Nil,
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('py-6 pr-8 font-medium text-gray-900')
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(line.cu)
																							])),
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('py-6 pr-8 font-medium text-gray-500')
																							]),
																						A2(
																							$elm$core$List$cons,
																							$author$project$Page$ShowPolicy$catalogueLabel(line.b6),
																							A2($elm$core$List$map, $author$project$Page$ShowPolicy$describeParameter, line.u))),
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('py-6 pr-8 font-medium text-gray-900')
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(line.cE.cu)
																							])),
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('py-6 pr-8 font-medium text-gray-900 text-right')
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								A2($author$project$Bill$formatMoney, policy.N.ca, line.bK))
																							]))
																					]));
																		},
																		policy.bm)),
																	A2(
																	$elm$html$Html$tfoot,
																	_List_Nil,
																	A2(
																		$elm$core$List$map,
																		function (_v3) {
																			var highlight = _v3.a;
																			var desc = _v3.b;
																			var amount = _v3.c;
																			return A2(
																				$elm$html$Html$tr,
																				_List_Nil,
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$classList(
																								_List_fromArray(
																									[
																										_Utils_Tuple2('py-2 pr-8 font-medium text-right', true),
																										_Utils_Tuple2('text-green-500 text-l', highlight),
																										_Utils_Tuple2('text-gray-500 text-xs', !highlight)
																									])),
																								$elm$html$Html$Attributes$colspan(3)
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(desc)
																							])),
																						A2(
																						$elm$html$Html$td,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$classList(
																								_List_fromArray(
																									[
																										_Utils_Tuple2('py-2 pr-8 font-medium text-right', true),
																										_Utils_Tuple2('text-green-500 text-2xl', highlight),
																										_Utils_Tuple2('text-gray-500 text-xs', !highlight)
																									]))
																							]),
																						_List_fromArray(
																							[
																								$elm$html$Html$text(
																								A2($author$project$Bill$formatMoney, policy.N.ca, amount))
																							]))
																					]));
																		},
																		$author$project$Page$ShowPolicy$totalRows(policy.N)))
																])),
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-6 mb-3 text-lg leading-6 font-medium text-gray-900')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Next steps')
																])),
															A2(
															$elm$html$Html$ol,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('list-decimal text-gray-700 text-sm mb-6 pl-10')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Please check your Certificate of Insurance to ensure you have complied with your Your Duty to take Reasonable Care not to Misrepresent')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Please ensure that all your information is correct and current, including information you have previously told us. ')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Please note if your information is not correct and up to date, you may not be covered. Please contact us immediately on 1300 000 000 if any of your details are incorrect.')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('If you do not wish to renew your insurance policy, or wish to discuss other cover options for Matilda, please contact our customer service team before 13 March 2021. You can still cancel your policy up to 21 days post your renewal date.')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('If you haven’t already, don’t forget to register for MyPet Portal to view and manage your policy as well as submit and track claims online.')
																		]))
																])),
															A2(
															$elm$html$Html$h3,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mt-6 mb-3 text-lg leading-6 font-medium text-gray-900')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Manage your policy online')
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-gray-700 text-sm')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Logging in online is a great and easy way to manage your policy online and find out more information. When you log into the account you can:            ')
																])),
															A2(
															$elm$html$Html$ol,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('list-decimal text-gray-700 text-sm my-6 pl-10')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Submit and track claims')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Check the details of your insurance cover')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Search the Help Centre')
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Update your personal and banking details')
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('bg-gray-50 px-6 py-6 rounded-md -mr-6 -mb-6 -ml-6')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$a,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('inline-flex justify-center py-4 px-20 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Login to manage your policy')
																		]))
																]))
														]))
												]))
										])),
									$author$project$Page$ShowPolicy$sidebar
								]))
						])),
					function () {
					var _v4 = model.Q;
					if ((!_v4.$) && (!_v4.a.$)) {
						var _v5 = _v4.a;
						return $author$project$Page$ShowPolicy$processingModal;
					} else {
						return $elm$html$Html$text('');
					}
				}()
				]));
	}
};
var $author$project$Page$ShowQuote$SetBillingCycleUnit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ShowQuote$billingCycleUnitLabel = function (u) {
	if (!u) {
		return 'Monthly billing';
	} else {
		return 'Yearly billing';
	}
};
var $author$project$Page$ShowQuote$billingCycleOptions = function (q) {
	var options = _List_fromArray(
		[0, 1]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bg-white rounded-md shadow-inner border border-gray-200')
			]),
		A2(
			$elm$core$List$map,
			function (u) {
				var active = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						function (u2) {
							return _Utils_eq(u2, u);
						},
						q.b0));
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('rounded-md relative w-1/2 py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8', true),
									_Utils_Tuple2('bg-orange-800 shadow text-white', active),
									_Utils_Tuple2('text-gray-700', !active)
								])),
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$ShowQuote$SetBillingCycleUnit(u))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Page$ShowQuote$billingCycleUnitLabel(u))
						]));
			},
			options));
};
var $author$project$Page$ShowQuote$Continue = {$: 2};
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $feathericons$elm_feather$FeatherIcons$alertCircle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'alert-circle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('8'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('16'),
					$elm$svg$Svg$Attributes$x2('12.01'),
					$elm$svg$Svg$Attributes$y2('16')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$checkCircle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'check-circle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M22 11.08V12a10 10 0 1 1-5.93-9.14')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('22 4 12 14.01 9 11.01')
				]),
			_List_Nil)
		]));
var $author$project$Page$ShowQuote$continueBanner = function (status) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fixed inset-x-0 bottom-0')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-orange-100 border-t border-orange-600')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex items-center justify-between flex-wrap')
									]),
								_List_fromArray(
									[
										function () {
										switch (status.$) {
											case 0:
												return $elm$html$Html$text('');
											case 5:
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-0 flex-1 flex items-center')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$feathericons$elm_feather$FeatherIcons$toHtml,
																	_List_Nil,
																	A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-700', $feathericons$elm_feather$FeatherIcons$alertCircle))
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mx-5 text-xl font-medium text-gray-700 truncate')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('We ran into an issue with your request')
																]))
														]));
											case 3:
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-0 flex-1 flex items-center')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$feathericons$elm_feather$FeatherIcons$toHtml,
																	_List_Nil,
																	A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-700', $feathericons$elm_feather$FeatherIcons$checkCircle))
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mx-5 text-xl font-medium text-gray-700 truncate')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Submitting…')
																]))
														]));
											default:
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-0 flex-1 flex items-center')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$feathericons$elm_feather$FeatherIcons$toHtml,
																	_List_Nil,
																	A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-6 h-auto stroke-current text-gray-700', $feathericons$elm_feather$FeatherIcons$checkCircle))
																])),
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('mx-5 text-xl font-medium text-gray-700 truncate')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Great! You\'ve chosen your plan, now let\'s get some details.')
																])),
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-xl px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700'),
																	$elm$html$Html$Events$onClick($author$project$Page$ShowQuote$Continue)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Continue')
																]))
														]));
										}
									}()
									]))
							]))
					]))
			]));
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{k: nodeList, g: nodeListSize, j: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $author$project$Page$ShowQuote$quoteItemByIndex = F2(
	function (i, items) {
		return A2(
			$elm$core$Array$get,
			i,
			$elm$core$Array$fromList(items));
	});
var $author$project$Page$ShowQuote$SetActiveQuoteItemNavItem = function (a) {
	return {$: 4, a: a};
};
var $feathericons$elm_feather$FeatherIcons$circle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'circle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil)
		]));
var $author$project$Page$ShowQuote$itemLabel = F2(
	function (n, item) {
		return A2(
			$elm$core$Maybe$withDefault,
			'Pet ' + $elm$core$String$fromInt(n + 1),
			item.cu);
	});
var $author$project$Page$ShowQuote$quoteItemNavItem = F3(
	function (current, n, item) {
		var isCurrent = _Utils_eq(current, n);
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('group inline-flex items-center py-4 px-3 border-b-2 font-medium text-lg', true),
							_Utils_Tuple2('border-orange-600 text-orange-600', isCurrent),
							_Utils_Tuple2('border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ', !isCurrent)
						])),
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Events$onClick(
					$author$project$Page$ShowQuote$SetActiveQuoteItemNavItem(n))
				]),
			_List_fromArray(
				[
					function () {
					var _v0 = _Utils_Tuple2(item.cJ, isCurrent);
					if (_v0.a.$ === 1) {
						if (_v0.b) {
							var _v1 = _v0.a;
							return A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'text-orange-600 -ml-0.5 mr-3 h-5 w-5', $feathericons$elm_feather$FeatherIcons$circle));
						} else {
							var _v2 = _v0.a;
							return A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'text-gray-400 -ml-0.5 mr-3 h-5 w-5', $feathericons$elm_feather$FeatherIcons$circle));
						}
					} else {
						if (_v0.b) {
							return A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'text-orange-600 -ml-0.5 mr-3 h-5 w-5', $feathericons$elm_feather$FeatherIcons$checkCircle));
						} else {
							return A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withClass, 'text-green-400 -ml-0.5 mr-3 h-5 w-5', $feathericons$elm_feather$FeatherIcons$checkCircle));
						}
					}
				}(),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Page$ShowQuote$itemLabel, n, item))
						]))
				]));
	});
var $author$project$Page$ShowQuote$quoteItemNav = F2(
	function (current, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('border-b border-gray-200')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$nav,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('-mb-px flex space-x-8')
						]),
					A2(
						$elm$core$List$indexedMap,
						$author$project$Page$ShowQuote$quoteItemNavItem(current),
						items))
				]));
	});
var $author$project$Page$ShowQuote$catalogueLabel = function (name) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-3 py-1 rounded-md text-sm font-medium bg-gray-100 text-gray-800')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(name)
			]));
};
var $author$project$Page$ShowQuote$describeParameter = function (param) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-3 py-1 rounded-md text-sm font-medium bg-gray-100 text-gray-800')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inline-block text-gray-600 mr-1.5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(param.i.cu + ': ')
					])),
				$elm$html$Html$text(param.X.cu)
			]));
};
var $author$project$Page$ShowQuote$SelectProduct = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $feathericons$elm_feather$FeatherIcons$x = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'x',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('18'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('18'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $author$project$Page$ShowQuote$benefitItem = function (v) {
	return (v.cY > 0) ? A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex space-x-3')
			]),
		_List_fromArray(
			[
				A2(
				$feathericons$elm_feather$FeatherIcons$toHtml,
				_List_Nil,
				A2($feathericons$elm_feather$FeatherIcons$withClass, 'mr-1 w-4 h-auto stroke-current text-green-500', $feathericons$elm_feather$FeatherIcons$check)),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-sm text-gray-500')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(v.cb)
					]))
			])) : A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex space-x-3')
			]),
		_List_fromArray(
			[
				A2(
				$feathericons$elm_feather$FeatherIcons$toHtml,
				_List_Nil,
				A2($feathericons$elm_feather$FeatherIcons$withClass, 'mr-1 w-4 h-auto stroke-current text-red-500', $feathericons$elm_feather$FeatherIcons$x)),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-sm text-gray-500')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(v.cb)
					]))
			]));
};
var $author$project$Page$ShowQuote$findBillingCycleOption = F2(
	function (options, u) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (o) {
				return _Utils_eq(o.cW, u);
			},
			options);
	});
var $author$project$Page$ShowQuote$billingAmount = F3(
	function (currency, options, u) {
		var _v0 = A2($author$project$Page$ShowQuote$findBillingCycleOption, options, u);
		if (_v0.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var o = _v0.a;
			return A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-2xl font-extrabold text-gray-900')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Bill$formatMoney, currency, o.cA))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-base font-medium text-gray-500')
							]),
						_List_fromArray(
							[
								function () {
								if (!u) {
									return $elm$html$Html$text('/month');
								} else {
									return $elm$html$Html$text('/year');
								}
							}()
							]))
					]));
		}
	});
var $author$project$Page$ShowQuote$productMatches = F2(
	function (p1, p2) {
		if (!p1.$) {
			var product = p1.a;
			return _Utils_eq(product.aU, p2.aU);
		} else {
			return false;
		}
	});
var $author$project$Page$ShowQuote$productOption = F4(
	function (currency, unit, item, premium) {
		var selected = A2($author$project$Page$ShowQuote$productMatches, item.cJ, premium.cE);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('rounded-lg shadow-sm divide-y divide-gray-200 grow lg:grow-0 lg:w-1/4', true),
							_Utils_Tuple2('bg-orange-200 border-gray-500', selected),
							_Utils_Tuple2('bg-white border-gray-200', !selected)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('p-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('text-lg leading-6 text-gray-900', true),
											_Utils_Tuple2('font-bold', selected),
											_Utils_Tuple2('font-medium', !selected)
										]))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(premium.cE.cu)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-4 text-sm text-gray-500')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Something about this product')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-8 w-full')
								]),
							_List_fromArray(
								[
									A3($author$project$Page$ShowQuote$billingAmount, currency, premium.b1, unit)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('mt-8 text-white block w-full rounded-md py-2 text-sm font-semibold text-center', true),
											_Utils_Tuple2('bg-orange-800', selected),
											_Utils_Tuple2('bg-gray-800 hover:bg-gray-900', !selected)
										])),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$ShowQuote$SelectProduct, item, premium.cE))
								]),
							_List_fromArray(
								[
									selected ? $elm$html$Html$text(premium.cE.cu + ' selected') : $elm$html$Html$text('Buy ' + premium.cE.cu)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pt-6 pb-8 px-6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-xs font-medium text-gray-900 tracking-wide uppercase')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('What\'s included')
								])),
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-6 space-y-4'),
									A2($elm$html$Html$Attributes$attribute, 'role', 'list')
								]),
							A2($elm$core$List$map, $author$project$Page$ShowQuote$benefitItem, premium.cE.b$))
						]))
				]));
	});
var $author$project$Page$ShowQuote$productOptions = F3(
	function (currency, selectedUnit, item) {
		var _v0 = item.bZ;
		if (!_v0.b) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Sorry, we can\'t offer you any products for your details')
					]));
		} else {
			var premiums = _v0;
			var unit = A2($elm$core$Maybe$withDefault, 1, selectedUnit);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex justify-evenly flex-wrap gap-5 lg:flex-nowrap')
					]),
				A2(
					$elm$core$List$map,
					A3($author$project$Page$ShowQuote$productOption, currency, unit, item),
					premiums));
		}
	});
var $author$project$Page$ShowQuote$quoteItemPanel = F3(
	function (currency, unit, item) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('my-6 flex flex-wrap gap-2')
						]),
					A2(
						$elm$core$List$cons,
						$author$project$Page$ShowQuote$catalogueLabel(item.b6),
						A2($elm$core$List$map, $author$project$Page$ShowQuote$describeParameter, item.u))),
					A3($author$project$Page$ShowQuote$productOptions, currency, unit, item)
				]));
	});
var $author$project$Page$ShowQuote$view = function (model) {
	var _v0 = model.f;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('min-h-full pt-16 pb-12 flex flex-col bg-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-shrink-0 flex justify-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('inline-flex')
										]),
									_List_fromArray(
										[
											A2(
											$feathericons$elm_feather$FeatherIcons$toHtml,
											_List_Nil,
											A2($feathericons$elm_feather$FeatherIcons$withClass, 'w-10 h-auto stroke-current text-gray-600 animate-spin', $feathericons$elm_feather$FeatherIcons$loader))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-14')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-sm font-semibold text-gray-600 uppercase tracking-wide')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Loading')
												])),
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mt-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Fetching your quote')
												]))
										]))
								]))
						]))
				]));
	} else {
		var quote = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$PurchasePathStep$purchasePathStepsNav,
					$elm$core$Maybe$Just(quote),
					_List_fromArray(
						[
							$author$project$PurchasePathStep$NewQuoteStep(2),
							$author$project$PurchasePathStep$ShowQuoteStep(1),
							$author$project$PurchasePathStep$EditQuoteStep(0),
							$author$project$PurchasePathStep$PayQuoteStep(0)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sm:flex sm:flex-col sm:align-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8')
										]),
									_List_fromArray(
										[
											$author$project$Page$ShowQuote$billingCycleOptions(quote)
										]))
								])),
							function () {
							var _v1 = model.m;
							if (_v1.$ === 5) {
								var error = _v1.a;
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mt-4')
										]),
									_List_fromArray(
										[
											$author$project$ErrorResponse$errorNotification(error)
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2($author$project$Page$ShowQuote$quoteItemNav, model.V, quote.h),
							function () {
							var _v2 = A2($author$project$Page$ShowQuote$quoteItemByIndex, model.V, quote.h);
							if (_v2.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var item = _v2.a;
								return A3($author$project$Page$ShowQuote$quoteItemPanel, quote.ca, quote.b0, item);
							}
						}()
						])),
					$author$project$Page$ShowQuote$readyToContinue(quote.h) ? $author$project$Page$ShowQuote$continueBanner(model.m) : $elm$html$Html$text('')
				]));
	}
};
var $author$project$Main$view = function (model) {
	return {
		b2: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-gray-100')
					]),
				_List_fromArray(
					[
						$author$project$Main$pageHeader,
						A2(
						$elm$html$Html$main_,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pt-10 pb-20')
							]),
						_List_fromArray(
							[
								function () {
								var _v0 = model.r;
								switch (_v0.$) {
									case 0:
										return $elm$html$Html$text('page not found');
									case 1:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$NewQuotePageMsg,
											$author$project$Page$NewQuote$view(pageModel));
									case 2:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$ShowQuotePageMsg,
											$author$project$Page$ShowQuote$view(pageModel));
									case 3:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$EditQuotePageMsg,
											$author$project$Page$EditQuote$view(pageModel));
									case 4:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$PayQuotePageMsg,
											$author$project$Page$PayQuote$view(pageModel));
									case 5:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$ShowPolicyPageMsg,
											$author$project$Page$ShowPolicy$view(pageModel));
									default:
										var pageModel = _v0.a;
										return A2(
											$elm$html$Html$map,
											$author$project$Main$PolicyBillingPageMsg,
											$author$project$Page$PolicyBilling$view(pageModel));
								}
							}()
							])),
						$author$project$Main$pageFooter
					]))
			]),
		cT: $author$project$Main$pageTitle(model.r)
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{cn: $author$project$Main$init, cw: $author$project$Main$UrlChanged, cx: $author$project$Main$LinkClicked, cQ: $author$project$Main$subscriptions, cX: $author$project$Main$update, cZ: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));