import 'stylesheets/application.scss'

import {
  Elm
} from 'elm/Main.elm';

document.addEventListener('DOMContentLoaded', () => {
  const app = Elm.Main.init();

  // Receive URLs to open in new window (downloading certificates)
  app.ports.newWindow.subscribe(function(url) {
    window.open(url, '_blank');
  });

  var stripe, card, key, clientSecret;

  function renderStripeForm (payload) {
    if (!window.Stripe) {
      return;
    }

    key = payload[0];
    clientSecret = payload[1];

    stripe = window.Stripe(key);
    let elements = stripe.elements();

    card = elements.create('card', {
      classes: {
        base: 'bg-white py-3 px-3 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm',
      }
    });
    // TODO hide postal code or prefill it
    card.mount('#card-element');

    return card;
  }

  //
  // Initial payment
  //

  app.ports.renderStripeForm.subscribe(function (key) {
    // key is a tuple / array of [ stripe-public-key, client-secret ]
    let card = renderStripeForm(key);

    card.on('ready', function () {
      app.ports.stripeFormReady.send(null);
    });
    card.on('change', function (event) {
      //console.log(event);
      let payload = {
        error: event.error || null,
        empty: !!event.empty,
        complete: !!event.complete
      };
      app.ports.stripeFormChange.send(payload);
    });
  });

  app.ports.confirmCardSetup.subscribe(function (billingName) {
    stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          name: billingName
        }
      }
    }).then((result) => {
      if(result.error) {
        app.ports.stripeSetupFailed.send(result.error.message);
      } else {
        app.ports.stripeSetupSucceeded.send(null);
      }
    });
  });

  //
  // Policy billing page (failed payment)
  //

  app.ports.renderStripePaymentMethodForm.subscribe(function (key) {
    // key is a tuple / array of [ stripe-public-key, client-secret ]
    let card = renderStripeForm(key);

    card.on('ready', function () {
      app.ports.stripePaymentMethodFormReady.send(null);
    });
    card.on('change', function (event) {
      //console.log(event);
      let payload = {
        error: event.error || null,
        empty: !!event.empty,
        complete: !!event.complete
      };
      app.ports.stripePaymentMethodFormChange.send(payload);
    });
  });

  app.ports.confirmPaymentMethod.subscribe(function (billingName) {
    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          name: billingName
        }
      },
      setup_future_usage: 'off_session'
    }).then((result) => {
      if(result.error) {
        app.ports.stripePaymentMethodFailed.send(result.error.message);
      } else {
        app.ports.stripePaymentMethodSucceeded.send(null);
      }
    });
  });
});
